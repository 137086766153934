import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateRangePicker, DateRangePickerProps, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';

type RSDateRangePickerProps = DateRangePickerProps<DateTime>;

export const RSDateRangePicker = (props: RSDateRangePickerProps) => {
  return (
    // In the future `adapterLocale="en-gb"` may take the browser's locale value
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-GB">
      <DateRangePicker
        {...props}
        reduceAnimations={true}
        // Do not render the default field seperator
        slots={{ ...props.slots, fieldSeparator: () => <></> }}
        slotProps={{
          ...props.slotProps,
          // Developers can actually put 'data-testid' to each slot. To make TypeScript happy, albeit unfavourable,
          // `ts-ignore`s are used.
          desktopPaper: {
            ...props.slotProps?.desktopPaper,
            className: 'rs-date-range-picker__calendar',
            // @ts-expect-error data-testid does not fit camelCase and that is expected
            'data-testid': 'rs-date-range-picker-calendar'
          },
          // @ts-expect-error data-testid does not fit camelCase and that is expected
          textField: { ...props.slotProps?.textField, 'data-testid': 'rs-date-range-picker-text-input' },
          calendarHeader: { ...props.slotProps?.calendarHeader, className: 'rs-date-range-picker__calendar-header' }
        }}
        // Do not render 'start' or 'end'
        localeText={{ start: '', end: '' }}
        className="rs-date-range-picker"
        data-testid="rs-date-range-picker"
        // See https://moment.github.io/luxon/#/formatting?id=table-of-tokens for a list of tokens
        dayOfWeekFormatter={(weekday) => `${weekday.toFormat('ccc')}`}
      />
    </LocalizationProvider>
  );
};

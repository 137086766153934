import { useMutation, useQuery } from '@apollo/client';
import { filesize } from 'filesize';
import { get, head, last, split } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OperationImageFetchSkipped } from './operation-image-fetch-skipped';
import { ImageType } from '../../../../__generated__/graphql';
import ImageIcon from '../../../../assets/icons/image.svg?react';
import MetadataIcon from '../../../../assets/icons/metadata.svg?react';
import { graphqlApiConfig } from '../../../../configs';
import { MUTATION_REMOTE_OPERATION_IMAGES } from '../../../../services/mutations';
import {
  QUERY_GET_DEVICE_ONLINE_STATUS,
  QUERY_GET_OPERATION_IMAGE,
  QUERY_GET_OPERATION_IMAGE_URLS
} from '../../../../services/queries';
import { ObjectUploadStatus, OperationImageType, SnackbarMessageType } from '../../../../types';
import { constructSnackbarMessage, getNonNullishDisplayValue, mapOnlineStatus } from '../../../../utilities';
import {
  BlobModalBase,
  BlobModalBaseProps,
  CodeViewer,
  ModalDrawerFooter,
  ModalDrawerHeader,
  UploadStatusChip
} from '../../../4-features';
import { InformationBlock, Loading, RSActionButton, RSButton } from '../../../5-elements';
import { useFormatTimezone } from '../../../hooks';

interface OperationImageModalProps extends Omit<BlobModalBaseProps, 'children'> {
  image: OperationImageType;
  deviceId: string;
  operationId: string;
  setOpenOperationImage: Dispatch<SetStateAction<boolean>>;
}

export const OperationImageModal = ({
  image,
  deviceId,
  operationId,
  setOpenOperationImage,
  ...props
}: OperationImageModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };

  const { formatWithDefaultTimezone } = useFormatTimezone();
  const [viewMode, setViewMode] = useState<'image' | 'metadata'>('image');

  /*
    The image URL will not be fetched if:
    (1) When it is not uploaded (image.status !== UploadDone), or
    (2) The modal is closed
  */
  const imageUrlFetchSkipped = image.status !== ObjectUploadStatus.UploadDone || !props.open;
  const {
    loading: loadingImageUrl,
    error: errorImageUrl,
    data: dataImageUrl
  } = useQuery(QUERY_GET_OPERATION_IMAGE_URLS, {
    skip: imageUrlFetchSkipped,
    fetchPolicy: 'no-cache',
    variables: { ids: [image.id], forDownload: true, imageType: ImageType.FullSize },
    onError: (error) => {
      sendMessageToSnackbar(
        t('operationsPage.operationDetails.imageModal.urlRequestError'),
        error.name,
        error.message,
        'error'
      );
    }
  });

  /*
    The online status of the device will not be fetched if:
    (1) The image has already been uploaded to the cloud, so the online status no longer matters; or
    (2) The modal is closed
  */
  const deviceOnlineStatusFetchSkipped = image.status === ObjectUploadStatus.UploadDone || !props.open;
  const { loading: loadingDeviceOnlineStatus, data: dataDeviceOnlineStatus } = useQuery(
    QUERY_GET_DEVICE_ONLINE_STATUS,
    {
      variables: { deviceId },
      skip: deviceOnlineStatusFetchSkipped,
      fetchPolicy: 'network-only',
      onError: (error) => {
        sendMessageToSnackbar(
          t('operationsPage.operationDetails.imageModal.deviceOnlineStatusRequestError'),
          error.name,
          error.message,
          'error'
        );
      }
    }
  );

  const [requestImage, { loading: loadingRequestImage, error: errorRequestImage }] = useMutation(
    MUTATION_REMOTE_OPERATION_IMAGES,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) => {
        sendMessageToSnackbar(
          t('operationsPage.operationDetails.imageModal.remoteImageRequestError'),
          error.name,
          error.message,
          'error'
        );
      }
    }
  );

  /*
    The purpose of the query (QUERY_GET_OPERATION_IMAGE) below is to check the status of the image and update the cache
    with the latest state.
    It will be skipped if:
    (1) The image has already been uploaded to the cloud (UploadDone), so the online status no longer matters; or
    (2) The image can no longer be requested (image.status is either UploadFailed or UploadFailedNotFound), or
    (3) The image is in draft status, or
    (4) An error occurred when requesting the image (mutation) from the device, or
    (5) The modal is closed
  */
  const updateOperationImageSkipped =
    image.status === ObjectUploadStatus.UploadDone ||
    image.status === ObjectUploadStatus.Draft ||
    image.status === ObjectUploadStatus.UploadFailed ||
    image.status === ObjectUploadStatus.UploadFailedNotFound ||
    Boolean(errorRequestImage) ||
    !props.open;
  useQuery(QUERY_GET_OPERATION_IMAGE, {
    variables: { imageId: image.id },
    skip: updateOperationImageSkipped,
    pollInterval: 3000,
    fetchPolicy: 'network-only',
    onError: (error) => {
      sendMessageToSnackbar(
        t('operationsPage.operationDetails.imageModal.updateImageDetailsError'),
        error.name,
        error.message,
        'error'
      );
    }
  });

  const getFileName = (fullPath: string): string =>
    last(split(fullPath, '/')) || (getNonNullishDisplayValue(undefined) as string);

  const handleRequestImage = (): void => {
    requestImage({ variables: { operationId, images: [{ path: image.fileName, recordedAt: image.recordedAt }] } });
  };

  const imageTitle = t('operationsPage.operationDetails.imageModal.title', {
    imageType: image.type,
    imageSequence: image.sequence,
    imageVisualType: image.visualType
  });

  const imageUrl = head(dataImageUrl?.requestDeviceOperationImageUrls)?.url;

  const requestingImageStatus =
    image.status === ObjectUploadStatus.UploadRequestQueued ||
    image.status === ObjectUploadStatus.UploadRequested ||
    loadingRequestImage;
  // The value which corresponds to "Online" is 2
  const deviceOnlineStatus = mapOnlineStatus(
    get(dataDeviceOnlineStatus, 'deviceByPK.deviceMeasurementValues[0].value')
  );

  return (
    <BlobModalBase {...props} onClose={() => setOpenOperationImage(false)}>
      <div className="operation-image-modal" data-testid="operation-image-modal">
        <ModalDrawerHeader
          title={imageTitle}
          actions={
            <div>
              <RSActionButton
                text={t('operationsPage.operationDetails.imageModal.actions.image')}
                startIcon={<ImageIcon className="rs-action-button__start-icon" />}
                iconContainerClassName="rs-action-button__icon-container__image"
                data-testid="operation-image-modal-image-button"
                onClick={() => setViewMode('image')}
                activated={viewMode === 'image'}
              />
              <RSActionButton
                text={t('operationsPage.operationDetails.imageModal.actions.metaData')}
                startIcon={<MetadataIcon className="rs-action-button__start-icon" />}
                iconContainerClassName="rs-action-button__icon-container__metadata"
                data-testid="operation-image-modal-metadata-button"
                disabled={image.status !== ObjectUploadStatus.UploadDone || !image.metaData}
                onClick={() => setViewMode('metadata')}
                activated={viewMode === 'metadata'}
              />
            </div>
          }
          handleClose={() => setOpenOperationImage(false)}
        />
        <div className="operation-image-modal__contents">
          {(imageUrlFetchSkipped || errorImageUrl) && !loadingDeviceOnlineStatus && (
            <OperationImageFetchSkipped
              hasErrorImageUrl={Boolean(errorImageUrl)}
              deviceOnlineStatus={deviceOnlineStatus}
              imageStatus={image.status}
              requestingImageStatus={requestingImageStatus}
              handleRequestImage={handleRequestImage}
            />
          )}
          {(loadingImageUrl || loadingDeviceOnlineStatus) && (
            <div className="operation-image-modal__image-loading" data-testid="operation-image-modal-image-loading">
              <Loading />
            </div>
          )}
          {imageUrl && !loadingImageUrl && viewMode === 'image' && (
            <div className="operation-image-modal__image-container">
              <img className="operation-image-modal__image" data-testid="operation-image-modal-image" src={imageUrl} />
            </div>
          )}
          {imageUrl && !loadingImageUrl && viewMode === 'metadata' && (
            <CodeViewer data={JSON.stringify(image.metaData, null, 2)} />
          )}
        </div>
        <ModalDrawerFooter
          informationBlocks={
            <>
              <InformationBlock
                label={t('operationsPage.operationDetails.imageModal.result')}
                value={<UploadStatusChip uploadStatus={image.status} />}
                data-testid="operation-image-modal-upload-result"
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.imageModal.size')}
                value={filesize(image.size || 0, { base: 10, round: 1 })}
              />
              <InformationBlock
                data-testid="operation-image-modal-file-name"
                extraClassNames={['information-block__file-name']}
                label={t('operationsPage.operationDetails.imageModal.fileName')}
                value={getFileName(image.fileName)}
                valueProps={{ title: getFileName(image.fileName) }}
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.imageModal.createdAt')}
                value={formatWithDefaultTimezone(image.recordedAt)}
              />
            </>
          }
          actions={
            <>
              {imageUrl ? (
                <a href={imageUrl} download data-testid="operation-image-modal-download-link">
                  <RSButton variant="contained" data-testid="operation-image-modal-download-button">
                    {t('operationsPage.operationDetails.imageModal.downloadImage')}
                  </RSButton>
                </a>
              ) : (
                <RSButton variant="contained" disabled={true} data-testid="operation-image-modal-download-button">
                  {t('operationsPage.operationDetails.imageModal.downloadImage')}
                </RSButton>
              )}
            </>
          }
        />
      </div>
    </BlobModalBase>
  );
};

import { CustomerPrograms, FlattenFunction } from '../../../../../types';

export type CustomerProgramRow = FlattenFunction<typeof generateCustomerProgramRows>;

export const generateCustomerProgramRows = (programs?: CustomerPrograms) => {
  if (!programs) {
    return [];
  }

  return programs.map((program) => ({
    id: program.id,
    name: program.name,
    performance: program.id,
    rocs: program.devicesAggregate.aggregate?.count || 0
  }));
};

import { useQuery } from '@apollo/client';
import { GridEventListener, GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { get, isEqual, pick, toNumber, toString } from 'lodash';
import qs from 'qs';
import { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CustomersFilterPanel } from './customers-filter-panel';
import { CustomersOverviewSearchParameters, customersOverviewStatesSchema } from './customers-overview-states-schema';
import { CustomerRow, customersDataGridColumns, generateCustomerRows } from './data-grid-configurations';
import { customersFilterFields, generateFilterQuery, generateSortQuery } from './generate-queries';
import { PerformanceMetricsCustomerAggregate } from './performance-metrics-customer-aggregate';
import { CustomerBoolExp, CustomerOrderBy } from '../../../__generated__/graphql';
import { appConfig } from '../../../configs';
import { DEFAULT_GET_CUSTOMERS_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants';
import { QUERY_GET_CUSTOMERS, QUERY_GET_CUSTOMER_NAMES } from '../../../services/queries';
import {
  calculatePaginationEndRow,
  calculatePaginationPageCount,
  calculatePaginationStartRow,
  filterValidUrlFields
} from '../../../utilities';
import { OverviewMainSectionWrapper } from '../../2-templates';
import { PaginationBar, RSDataGrid } from '../../4-features';
import { ScreenTitle } from '../../5-elements';
import { ErrorPage } from '../error-page';

export const CustomersPageContent = (): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParamsLoaded, setQueryParamsLoaded] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const [filterOptions, setFilterOptions] = useState<CustomerBoolExp>({});
  const [sortingOptions, setSortingOptions] = useState<CustomerOrderBy[]>(DEFAULT_GET_CUSTOMERS_SORT_BY);

  const { loading, data, error } = useQuery(QUERY_GET_CUSTOMERS, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: sortingOptions,
      filters: filterOptions
    },
    fetchPolicy: 'cache-and-network',
    skip: !queryParamsLoaded
  });
  const {
    loading: loadingCustomerNames,
    data: dataCustomerNames,
    error: errorCustomerNames
  } = useQuery(QUERY_GET_CUSTOMER_NAMES, { fetchPolicy: 'network-only' });

  const searchParameters = qs.parse(searchParams.toString());

  const validUrlFields = filterValidUrlFields<CustomersOverviewSearchParameters>(
    searchParameters,
    customersOverviewStatesSchema
  );

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<CustomerRow>): void => {
    navigate(`${appConfig.basePath}/customers/${params.id}`);
  };

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }

    // Set sorting
    const sortParameter = get(validUrlFields, 'sort');
    const sortQuery = generateSortQuery(sortParameter);
    if (!isEqual(sortQuery, sortingOptions)) {
      setSortingOptions(sortQuery);
    }
    // Set filtering
    const filterParameters = pick(validUrlFields, customersFilterFields);
    const filterQuery = generateFilterQuery(filterParameters);
    if (!isEqual(filterQuery, filterOptions)) {
      setFilterOptions(filterQuery);
    }

    if (!queryParamsLoaded) {
      setQueryParamsLoaded(true);
    }
    // `searchParams` is a URLSearchParams whilst `routerLocation.search` is a string - compare string-type dependency
    // aligns with "useEffect dependencies compare the value change"
  }, [routerLocation.search]);

  const groupedError = error || errorCustomerNames;

  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const isLoading = loading || loadingCustomerNames;

  return (
    <OverviewMainSectionWrapper
      filter={<CustomersFilterPanel customerNames={dataCustomerNames} defaultValues={{ name: validUrlFields.name }} />}
    >
      <section className="customers-page-content" data-testid="customers-page-content">
        <div className="customers-page-content__overview-top-bar">
          <ScreenTitle title={t('customersPage.title')} />
          <PerformanceMetricsCustomerAggregate
            queryHookOptions={{
              skip: !queryParamsLoaded,
              variables: { filters: filterOptions },
              fetchPolicy: 'cache-and-network'
            }}
          />
        </div>
        <div className="customers-page-content__data-grid-container">
          <RSDataGrid
            columns={customersDataGridColumns}
            rows={generateCustomerRows(data)}
            loading={isLoading}
            onRowClick={handleRowClick}
            data-testid="customers-page-content-data-grid"
          />
        </div>
        <div className="customers-page-content__pagination">
          <PaginationBar
            isLoading={loading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(
              paginationModel.page,
              DEFAULT_PAGE_SIZE,
              data?.customersAggregate.aggregate?.count
            )}
            rowCount={data?.customersAggregate.aggregate?.count}
            pageCount={calculatePaginationPageCount(data?.customersAggregate.aggregate?.count)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = qs.parse(searchParams.toString());
              setSearchParams(
                qs.stringify({ ...originalSearchParamsObject, page: toString(page) }, { arrayFormat: 'brackets' })
              );
            }}
            page={paginationModel.page}
          />
        </div>
      </section>
    </OverviewMainSectionWrapper>
  );
};

import { flatten, omit } from 'lodash';
import { ZodTypeAny } from 'zod';

export const filterValidUrlFields = <T>(
  searchParameters: { [key: string]: unknown },
  // Would be the best to use ZodType<T>, however, due to this bug in Zod, this is not possible yet:
  // https://github.com/colinhacks/zod/issues/2588
  // We have a number() field in combination with preprocess() which results in a type conflict in `unknown`
  validationSchema: ZodTypeAny
): T => {
  const validationResult = validationSchema.safeParse(searchParameters);
  if (validationResult.error) {
    const errorFields = flatten(validationResult.error.issues.map((issue) => issue.path)) as string[];
    const validFields = omit(searchParameters, errorFields) as T;
    const parsedValidFields = validationSchema.safeParse(validFields);
    return (parsedValidFields.data as T) || ({} as T);
  }

  return (validationResult.data as T) || ({} as T);
};

import { CSSProperties, JSX, useEffect, useMemo, useRef, useState } from 'react';

import { RSActionLink } from '../../5-elements';

interface LongTextWithExpansionProps {
  amountOfTextLines: number;
  condensedTextHeight: number;
  text: string;
  moreTextBtnText: string;
  lessTextBtnText: string;
}

export const LongTextWithExpansion = ({
  amountOfTextLines,
  condensedTextHeight,
  text,
  moreTextBtnText,
  lessTextBtnText
}: LongTextWithExpansionProps): JSX.Element => {
  const [textStyle, setTextStyle] = useState<CSSProperties>({ height: 'auto' });
  const [fullTextHeight, setFullTextHeight] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const condensedTextStyle = useMemo(() => {
    return { height: condensedTextHeight, WebkitLineClamp: amountOfTextLines };
  }, [condensedTextHeight, amountOfTextLines]);
  const refText = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (refText.current) {
      setFullTextHeight(refText.current.clientHeight);
      setTextStyle(condensedTextStyle);
    }
  }, [condensedTextStyle]);

  const handleClick = (): void => {
    if (showMore) {
      setTextStyle(condensedTextStyle);
    } else {
      setTextStyle({ height: fullTextHeight, WebkitLineClamp: 'unset' });
    }
    setShowMore(!showMore);
  };

  return (
    <div className="long-text-with-expansion" data-testid="long-text-with-expansion">
      <p
        className="long-text-with-expansion__value"
        data-testid="long-text-with-expansion-value"
        ref={refText}
        style={textStyle}
      >
        {text}
      </p>
      {fullTextHeight > condensedTextHeight && (
        <RSActionLink handleClick={handleClick}>{showMore ? lessTextBtnText : moreTextBtnText}</RSActionLink>
      )}
    </div>
  );
};

import { DeviceOperationBoolExp } from '../../../../__generated__/graphql';

/*
  Here the `deviceId` is fetched through the URL match from react-router-dom. Although the type can be `undefined` in
  theory, in reality the user cannot reach the DeviceDetailsPage if `deviceId` is undefined. This method will only
  be called if the user has successfully reached the DeviceDetailsPage, therefore in practice `deviceId` shall not
  be `undefined`. If the user managed to puts `undefined` to generate the query, let it fail (hasError = true for the
  metrics component).
*/
export const generateDeviceDetailsPerformanceMetricsFilter = (deviceId: string): DeviceOperationBoolExp => {
  return { device: { id: { _eq: deviceId } } };
};

import classNames from 'classnames';
import { JSX, PropsWithChildren, ReactNode } from 'react';

interface RSCardProps extends PropsWithChildren {
  title?: ReactNode;
  extraClassNames?: string[];
  dataTestId?: string;
}

export const RSCard = (props: RSCardProps): JSX.Element => {
  const cardClassNames = classNames('rs-card', props.extraClassNames);
  return (
    <div className={cardClassNames} data-testid={props.dataTestId}>
      <div className="rs-card__title">{props.title}</div>
      <div className="rs-card__contents">{props.children}</div>
    </div>
  );
};

import { isNil } from 'lodash';

import i18n from '../../i18n';

export const getNonNullishDisplayValue = <T extends string | boolean | number>(
  value?: T | null,
  handleEmptyString: boolean = false
): T => {
  if (isNil(value) || (handleEmptyString === true && value === '')) {
    return i18n.t('noData') as T;
  }
  return value;
};

import { GetOperationResultsQuery } from '../../../../../__generated__/graphql';
import i18n from '../../../../../i18n';
import { RSAutocompleteValue } from '../../../../../types';
import { OperationResultOption } from '../../../../4-features';

i18n.init();

// A higher-order function, which returns a function to generate RSAutocomplete options.
export const getOperationResultDisplayOption = (operationResults?: GetOperationResultsQuery) => {
  const operationResultDisplayOption = (option: RSAutocompleteValue): JSX.Element => {
    const resultObject = operationResults?.deviceOperationResults.find((item) => item.code === option);
    return <OperationResultOption resultObject={resultObject} />;
  };

  return operationResultDisplayOption;
};

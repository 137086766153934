import classNames from 'classnames';

import IconDash from '../../../assets/icons/dash.svg?react';
import IconEqualSign from '../../../assets/icons/equal-sign.svg?react';
import IconGraphArrow from '../../../assets/icons/graph-arrow.svg?react';

export const MetricChangeIcon = ({ value }: { value?: number | null }): JSX.Element => {
  if (value === 0) {
    return <IconEqualSign className="metric-change-icon__neutral" data-testid="metric-change-icon-equal-sign" />;
  }

  if (value === null || value === undefined) {
    return <IconDash className="metric-change-icon__no-data" data-testid="metric-change-icon-dash" />;
  }

  return (
    <IconGraphArrow
      className={classNames('metric-change-icon__change', {
        'metric-change-icon__change--positive': value > 0,
        'metric-change-icon__change--negative': value < 0
      })}
      data-testid="metric-change-icon-graph-arrow-sign"
    />
  );
};

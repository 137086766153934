import { DateTimeFormatOptions } from 'luxon';
import { useContext } from 'react';

import { formatTimestamp } from '../../utilities';
import { UserTimezoneContext } from '../contexts';

export interface UseFormatTimezoneHook {
  formatWithDefaultTimezone: (
    timestamp: string,
    locale?: string,
    localeStringSettings?: DateTimeFormatOptions
  ) => string;
}

export const useFormatTimezone = (): UseFormatTimezoneHook => {
  const { userTimezone } = useContext(UserTimezoneContext);
  const formatWithDefaultTimezone = (
    isoDateTimeString: string,
    locale?: string,
    localeStringSettings?: DateTimeFormatOptions
  ) => {
    return formatTimestamp(isoDateTimeString, userTimezone, locale, localeStringSettings);
  };
  return { formatWithDefaultTimezone };
};

import { Skeleton } from '@mui/material';
import classNames from 'classnames';

import { MetricChangeData, MetricLoadingError } from '../../../types';
import { getMetricChangeValue } from '../../../utilities';
import { MetricChangeIcon } from '../../5-elements';

interface MetricChangeProps extends MetricLoadingError {
  metricChange: MetricChangeData;
}

export const MetricChange = ({ metricChange, hasError, loading }: MetricChangeProps): JSX.Element => {
  if (loading) {
    return (
      <Skeleton variant="text" className="metric-change-value__loading" data-testid="metric-change-value-loading" />
    );
  }

  if (hasError) {
    return <></>;
  }

  return (
    <>
      <MetricChangeIcon value={metricChange.value} />
      <p
        className={classNames('metric-change-value', {
          'metric-change-value--neutral':
            metricChange.value === 0 || metricChange.value === null || metricChange.value === undefined,
          'metric-change-value--positive':
            metricChange.value !== null && metricChange.value !== undefined && metricChange.value > 0,
          'metric-change-value--negative':
            metricChange.value !== null && metricChange.value !== undefined && metricChange.value < 0
        })}
        data-testid="metric-change-value"
      >
        {getMetricChangeValue(metricChange.value, metricChange.decimalPlaces)}
        {metricChange.displayUnit}
      </p>
    </>
  );
};

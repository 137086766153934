import { JSX, ReactNode } from 'react';

interface SiteCardInformationBlockProps {
  title: string;
  value?: ReactNode;
}

export const SiteCardInformationBlock = (props: SiteCardInformationBlockProps): JSX.Element => {
  return (
    <div className="site-card__information-block">
      <h4 className="site-card__information-block-title">{props.title}</h4>
      <div className="site-card__information-block-value">{props.value}</div>
    </div>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_TYPES = gql(`
  query getDeviceTypes {
    deviceTypes(orderBy: { name: ASC }) {
      id
      modelNumber
      name
    }
  }
`);

import { first } from 'lodash';

import { GetOperationsQuery } from '../../../../__generated__/graphql';
import { FlattenFunction } from '../../../../types';
import {
  calculateDuration,
  formatTimestamp,
  generateOperationResultLabel,
  getAnnotationStatus,
  mapOperationalLifeCycleDisplayLabel
} from '../../../../utilities';
import { DataGridOperationResultCellProps } from '../../../4-features';

export type OperationRow = FlattenFunction<typeof generateOperationRows>;

export const generateOperationRows = (data?: GetOperationsQuery, userTimezone?: string) => {
  if (!data) {
    return [];
  }

  return data.deviceOperations.map((deviceOperation) => {
    const annotationStatus = getAnnotationStatus(
      deviceOperation.deviceOperationResult?.id,
      deviceOperation.deviceOperationAnnotatedResult?.id
    );
    return {
      id: deviceOperation.id,
      serialNumber: {
        serialNumber: deviceOperation.device.serialNumber,
        sequence: deviceOperation.sequence
      },
      result: {
        annotationStatus: annotationStatus,
        finalResultName: generateOperationResultLabel(deviceOperation),
        annotatedResultName: deviceOperation.deviceOperationAnnotatedResult?.name,
        originalResultName: deviceOperation.deviceOperationResult?.name,
        annotatedTimestamp: first(deviceOperation.deviceOperationAnnotationAudits)?.annotatedAt,
        success: deviceOperation.deviceOperationFinalResult?.isSuccessful,
        hasEndedAt: Boolean(deviceOperation.endAt),
        hasOperationResult: Boolean(deviceOperation.deviceOperationResult)
      } as DataGridOperationResultCellProps,
      dateTime: formatTimestamp(deviceOperation.startAt, userTimezone),
      duration: calculateDuration(deviceOperation.startAt, deviceOperation.endAt),
      operationalLifeCycle: mapOperationalLifeCycleDisplayLabel(deviceOperation.operationalLifeCycle),
      customer: deviceOperation.device.site.customer.company.name,
      site: deviceOperation.device.site.name,
      program: deviceOperation.device.program?.name,
      customerId: deviceOperation.device.site.customer.company.id,
      deviceId: deviceOperation.device.id
    };
  });
};

import {
  DeviceOperationBoolExp,
  GetDeviceOperationsPerformanceQueryVariables,
  OperationalLifeCycle
} from '../../__generated__/graphql';
import { getPerformanceDateRanges } from '../get-performance-date-ranges';

export const generatePerformanceMetricsFilters = (
  filters: DeviceOperationBoolExp
): GetDeviceOperationsPerformanceQueryVariables => {
  const basicQueryParameters: DeviceOperationBoolExp = {
    operationName: { _eq: 'ACDCycle' },
    operationalLifeCycle: { _eq: OperationalLifeCycle.Operational }
  };

  const performanceDateRanges = getPerformanceDateRanges();
  return {
    filtersPrevSuccess: {
      ...filters,
      ...basicQueryParameters,
      startAt: { _gte: performanceDateRanges.prevStartDate, _lte: performanceDateRanges.startDate },
      deviceOperationFinalResult: { isSuccessful: { _eq: true } }
    },
    filtersSuccess: {
      ...filters,
      ...basicQueryParameters,
      startAt: { _gte: performanceDateRanges.startDate, _lte: performanceDateRanges.endDate },
      deviceOperationFinalResult: { isSuccessful: { _eq: true } }
    },
    filtersPrevFailed: {
      ...filters,
      ...basicQueryParameters,
      startAt: { _gte: performanceDateRanges.prevStartDate, _lte: performanceDateRanges.startDate },
      deviceOperationFinalResult: { isSuccessful: { _eq: false } }
    },
    filtersFailed: {
      ...filters,
      ...basicQueryParameters,
      startAt: { _gte: performanceDateRanges.startDate, _lte: performanceDateRanges.endDate },
      deviceOperationFinalResult: { isSuccessful: { _eq: false } }
    }
  };
};

import { JSX } from 'react';

import { GetCustomerDetailsQuery } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types';
import { SiteCard } from '../../../4-features';

type CustomerSites = Flatten<GetCustomerDetailsQuery['customers']>['sites'];
type CustomerSite = Flatten<CustomerSites>;
interface CustomerSitesListProps {
  sites: CustomerSites;
}

export const CustomerSitesList = ({ sites }: CustomerSitesListProps): JSX.Element => {
  const getSitePrograms = (site: CustomerSite): string[] => {
    const programs = site.devices.map((device) => device.program?.name);
    return programs.filter((program) => Boolean(program) === true) as string[];
  };

  return (
    <div className="customer-sites-list">
      {sites.map((site) => (
        <SiteCard
          key={site.id}
          id={site.id}
          name={site.name}
          devicesCount={site.devicesAggregate.aggregate?.count}
          programs={getSitePrograms(site)}
          address={site.location.formattedAddress}
          latitude={site.location.latitude}
          longitude={site.location.longitude}
        />
      ))}
    </div>
  );
};

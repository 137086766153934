import { useAuth0 } from '@auth0/auth0-react';
import { JSX } from 'react';

import RocsysLogo from '../../../assets/logos/rocsys-logo.svg?react';
import { RSLinkTimezone, TimezoneSelect, UserMenu } from '../../4-features';

export const PortalHeader = (): JSX.Element => {
  const { user } = useAuth0();

  return (
    <header className="portal-header" data-testid="portal-header">
      <RSLinkTimezone to="/portal" data-testid="portal-header-logo-link">
        <div className="portal-header__logo">
          <RocsysLogo className="portal-header__logo-rocsys" />
        </div>
      </RSLinkTimezone>
      <div className="portal-header__actions">
        {user && <TimezoneSelect />}
        <div className="portal-header__user">
          <UserMenu userName={user?.name} email={user?.email} imageUrl={user?.picture} />
        </div>
      </div>
    </header>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_OPERATION_IMAGE_URLS = gql(`
  query getOperationImageUrls($ids: [String!]!, $forDownload: Boolean, $imageType: ImageType) {
    requestDeviceOperationImageUrls(ids: $ids, forDownload: $forDownload, imageType: $imageType) {
      url
      id
    }
  }
`);

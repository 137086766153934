import { gql } from '../../../__generated__/gql';

export const QUERY_GET_ALL_DEVICE_OPERATIONS_PERFORMANCE = gql(`
  query getAllDeviceOperationsPerformance(
    $filtersSuccess: DeviceOperationBoolExp
    $filtersFailed: DeviceOperationBoolExp
  ) {
    totalACDCycleOperationsSuccess: deviceOperationsAggregate(where: $filtersSuccess) {
      aggregate {
        count
      }
    }
    totalACDCycleOperationsFailed: deviceOperationsAggregate(where: $filtersFailed) {
      aggregate {
        count
      }
    }
  }
`);

import CheckmarkIcon from '../../../assets/icons/check-mark.svg?react';
import CrossIcon from '../../../assets/icons/cross.svg?react';
import ImageCircleIcon from '../../../assets/icons/image-circle.svg?react';
import InProgressIcon from '../../../assets/icons/in-progress.svg?react';
import { ObjectUploadStatus } from '../../../types';
import { mapObjectUploadStatus } from '../../../utilities';
import { RSChip } from '../../5-elements';

interface UploadStatusChipProps {
  uploadStatus: ObjectUploadStatus;
}

export const UploadStatusChip = ({ uploadStatus }: UploadStatusChipProps) => {
  switch (uploadStatus) {
    case ObjectUploadStatus.UploadDone:
      return (
        <RSChip
          data-testid="upload-status-chip-upload-done"
          label={mapObjectUploadStatus(uploadStatus)}
          icon={<CheckmarkIcon />}
          color="success"
        />
      );
    case ObjectUploadStatus.UploadFailed:
    case ObjectUploadStatus.UploadFailedNotFound:
    case ObjectUploadStatus.UploadRequestFailed:
      return (
        <RSChip
          data-testid={`upload-status-chip-${uploadStatus}`}
          label={mapObjectUploadStatus(uploadStatus)}
          icon={<CrossIcon />}
          color="error"
        />
      );
    case ObjectUploadStatus.Draft:
      return (
        <RSChip
          data-testid="upload-status-chip-draft"
          label={mapObjectUploadStatus(uploadStatus)}
          icon={<ImageCircleIcon className="upload-status-chip__image-circle-icon" />}
        />
      );
    default:
      return (
        <RSChip
          data-testid="upload-status-chip-in-progress"
          label={mapObjectUploadStatus(uploadStatus)}
          icon={<InProgressIcon />}
        />
      );
  }
};

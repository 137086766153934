import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps
} from '@mui/material';
import { omit } from 'lodash';
import { JSX } from 'react';

import AngleBracketDownIcon from '../../../assets/icons/angle-brackets-down.svg?react';

interface RSAccordionProps extends Omit<AccordionProps, 'children'> {
  accordionSummaryProps: AccordionSummaryProps;
  accordionDetailsProps: AccordionDetailsProps;
}

export const RSAccordion = ({
  accordionSummaryProps,
  accordionDetailsProps,
  ...props
}: RSAccordionProps): JSX.Element => {
  const accordionSummaryPropsNoChildren = omit(accordionSummaryProps, ['children']);
  const accordionDetailsPropsNoChildren = omit(accordionDetailsProps, ['children']);

  return (
    <Accordion {...props} className="rs-accordion" data-testid="rs-accordion">
      <AccordionSummary
        {...accordionSummaryPropsNoChildren}
        className="rs-accordion__summary"
        data-testid="rs-accordion-summary"
        expandIcon={<AngleBracketDownIcon className="rs-accordion__summary-expand-icon" />}
      >
        <div className="rs-accordion__summary-wrapper">{accordionSummaryProps.children}</div>
      </AccordionSummary>
      <AccordionDetails
        {...accordionDetailsPropsNoChildren}
        className="rs-accordion__details"
        data-testid="rs-accordion-details"
      >
        {accordionDetailsProps.children}
      </AccordionDetails>
    </Accordion>
  );
};

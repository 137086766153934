import { SnackbarKey, closeSnackbar } from 'notistack';

import CrossIcon from '../../../../assets/icons/cross.svg?react';

// Cannot use interface as the provider configuration `action: RSSnackbarCloseButton` need to be implemented in this way
export const RSSnackbarCloseButton = (snackbarId: SnackbarKey): JSX.Element => (
  <button className="rs-snackbar-close-button" onClick={() => closeSnackbar(snackbarId)}>
    <CrossIcon className="rs-snackbar-close-button__icon" />
  </button>
);

import { JSX, PropsWithChildren } from 'react';

interface OverviewMainSectionWrapperProps extends PropsWithChildren {
  filter: JSX.Element;
}

export const OverviewMainSectionWrapper = (props: OverviewMainSectionWrapperProps): JSX.Element => {
  return (
    <section className="overview-main-section-wrapper">
      <div className="overview-main-section-wrapper__filter">{props.filter}</div>
      <div className="overview-main-section-wrapper__content">{props.children}</div>
    </section>
  );
};

import i18n from '../../i18n';
import { OnlineStatus } from '../../types';

export const mapOnlineStatus = (status?: number): OnlineStatus => {
  if (status === 2) {
    return OnlineStatus.Online;
  }
  if (status === 1) {
    return OnlineStatus.HeartbeatOnly;
  }
  return OnlineStatus.Offline;
};

export const mapOnlineStatusEnumToValue = (status: OnlineStatus): number => {
  switch (status) {
    case OnlineStatus.Online:
      return 2;
    case OnlineStatus.HeartbeatOnly:
      return 1;
    case OnlineStatus.Offline:
    default:
      return 0;
  }
};

export const mapOnlineStatusDisplayLabel = (status: OnlineStatus): string => {
  if (status === OnlineStatus.Online) {
    return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.online');
  }
  if (status === OnlineStatus.HeartbeatOnly) {
    return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.heartbeatOnly');
  }
  return i18n.t('deviceDetailsPage.deviceHealth.connectivityState.offline');
};

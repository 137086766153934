import { GetCustomersQuery } from '../../../../__generated__/graphql';
import { FlattenFunction } from '../../../../types';

export type CustomerRow = FlattenFunction<typeof generateCustomerRows>;

export const generateCustomerRows = (data?: GetCustomersQuery) => {
  if (!data) {
    return [];
  }

  return data.customers.map((customer) => ({
    id: customer.companyId,
    name: customer.company.name,
    devicesCount: customer.devicesAggregate.aggregate?.count || 0,
    sitesCount: customer.sitesAggregate.aggregate?.count || 0,
    operationalPerformance: {
      acdCycleOperationsSuccessRate: customer.customerCurrentPerformance?.performanceLastPeriodPct ?? null,
      acdCycleOperationsSuccessRateChange: customer.customerCurrentPerformance?.performanceDiff ?? null,
      acdCycleOperationsCount: customer.customerCurrentPerformance?.totalLastPeriod ?? null,
      acdCycleOperationsCountChange: customer.customerCurrentPerformance?.totalDiffPct ?? null
    },
    programsCount: customer.programsAggregate.aggregate?.count || 0
  }));
};

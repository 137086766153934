import { useQuery } from '@apollo/client';
import qs from 'qs';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteCardInformationBlock } from './site-card-information-block';
import { SiteCardMetric } from './site-card-metric';
import { SiteCardPrograms } from './site-card-programs';
import { SiteCardTitle } from './site-card-title';
import { DeviceOperationBoolExp } from '../../../__generated__/graphql';
import { appConfig } from '../../../configs';
import { QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE } from '../../../services/queries';
import {
  calculateACDCycleOperationsResultSuccessRates,
  generatePerformanceMetricsFilters,
  getAcdCycleOperationsCounts
} from '../../../utilities';
import { InternalLink, RSCard, RSTooltip } from '../../5-elements';

export interface SiteCardProps {
  id: string;
  name: string;
  address: string | undefined | null;
  latitude: number;
  longitude: number;
  devicesCount?: number;
  programs?: string[];
}

export const SiteCard = (props: SiteCardProps): JSX.Element => {
  const { t } = useTranslation();
  const siteFilters: DeviceOperationBoolExp = { device: { siteId: { _eq: props.id } } };
  const { loading, data, error } = useQuery(QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE, {
    variables: { ...generatePerformanceMetricsFilters(siteFilters) },
    fetchPolicy: 'no-cache'
  });

  const acdCycleOperationsCount = getAcdCycleOperationsCounts(data);

  const successRates = calculateACDCycleOperationsResultSuccessRates(
    acdCycleOperationsCount.acdCycleOperationsSuccess,
    acdCycleOperationsCount.acdCycleOperationsFailed,
    acdCycleOperationsCount.acdCycleOperationsPrevSuccess,
    acdCycleOperationsCount.acdCycleOperationsPrevFailed
  );

  return (
    <RSCard
      extraClassNames={['site-card']}
      title={
        <SiteCardTitle
          address={props.address}
          name={props.name}
          latitude={props.latitude}
          longitude={props.longitude}
        />
      }
      dataTestId="site-card"
    >
      <div className="site-card__contents">
        <RSTooltip title={t('performanceMetricsBlocks.tooltips.operationalPerformance')}>
          <div>
            <SiteCardInformationBlock
              title={t('siteCard.title.operationalPerformance')}
              value={
                <SiteCardMetric
                  loading={loading}
                  hasError={!!error}
                  metric={{
                    value: successRates.acdCycleOperationsSuccessRate,
                    displayUnit: t('units.percentage')
                  }}
                  metricChange={{
                    value: successRates.acdCycleOperationsSuccessRateChange,
                    displayUnit: t('units.percentage')
                  }}
                />
              }
            />
          </div>
        </RSTooltip>
        <SiteCardInformationBlock title={t('siteCard.title.devices')} value={props.devicesCount || 0} />
        <RSTooltip title={t('performanceMetricsBlocks.tooltips.totalCount')}>
          <div>
            <SiteCardInformationBlock
              title={t('siteCard.title.acdCycles')}
              value={
                <SiteCardMetric
                  loading={loading}
                  hasError={!!error}
                  metric={{ value: successRates.acdCycleOperationsCount || 0, decimalPlaces: 0 }}
                  metricChange={{
                    value: successRates.acdCycleOperationsCountChange,
                    displayUnit: t('units.percentage')
                  }}
                />
              }
            />
          </div>
        </RSTooltip>
        <SiteCardInformationBlock
          title={t('siteCard.title.programs')}
          value={<SiteCardPrograms programs={props.programs} />}
        />
      </div>
      <div className="site-card__devices">
        <InternalLink
          text={t('siteCard.viewDevices')}
          to={`${appConfig.basePath}/devices?${qs.stringify({ site: props.name })}`}
          disabled={!props.devicesCount}
        />
      </div>
    </RSCard>
  );
};

import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText } from '@mui/material';
import { isEmpty, isEqual, pick, toNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AutoImageCollectionOptions,
  AutoLogCollectionOptions,
  DeviceCloudSettingsEditRequest,
  DeviceCloudSettingsQueryRequest,
  deviceCloudSettingsEditValidationSchema
} from './validation-schema';
import { graphqlApiConfig } from '../../../../configs';
import { MUTATION_MODIFY_DEVICE_CLOUD_SETTINGS } from '../../../../services/mutations';
import { SnackbarMessageType } from '../../../../types';
import { constructSnackbarMessage } from '../../../../utilities';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import { RSRadio, RSRadioGroup, RSSwitch, RSSwitchProps, RSTextInput } from '../../../5-elements';

interface DeviceCloudSettingsEditProps extends Omit<RSDrawerProps, 'children'> {
  setOpenDeviceCloudSettingsEdit: (value: SetStateAction<boolean>) => void;
  defaultValues: DeviceCloudSettingsEditRequest;
}

export const DeviceCloudSettingsEdit = ({
  setOpenDeviceCloudSettingsEdit,
  defaultValues,
  ...props
}: DeviceCloudSettingsEditProps): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [retentionAcdCyclesLabelFocused, setRetentionAcdCyclesLabelFocused] = useState<boolean>(false);
  const [retentionMeasurementsLabelFocused, setRetentionMeasurementsLabelFocused] = useState<boolean>(false);
  const [modifyDeviceCloudSettings, { loading }] = useMutation(MUTATION_MODIFY_DEVICE_CLOUD_SETTINGS, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error');
    }
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
    reset
  } = useForm<DeviceCloudSettingsEditRequest>({
    resolver: zodResolver(deviceCloudSettingsEditValidationSchema),
    defaultValues
  });

  const watchValues = watch();

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenDeviceCloudSettingsEdit(false);
  };

  const handleCancel = (): void => {
    const formHasChanges = !isEqual(getValues(), defaultValues);
    if (formHasChanges) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenDeviceCloudSettingsEdit(false);
    }
  };

  const onSubmit: SubmitHandler<DeviceCloudSettingsEditRequest> = (data) => {
    const formHasChanges = !isEqual(getValues(), defaultValues);
    if (!formHasChanges) {
      setOpenDeviceCloudSettingsEdit(false);
      setShowConfirmationModal(false);
      return;
    }

    // To style the form based on the design, the values (`data` here) does not fit the request that the GraphQL query
    // needs. Therefore we convert the submitted `data` to the format that fits the GraphQL query request.
    // NOTE: If the `data` reaches here, it has already been validated. Therefore herein we make the assumption that the
    // `data` is valid.
    const dataToSubmit: DeviceCloudSettingsQueryRequest = pick(data, [
      'deviceId',
      'notificationsEnabled',
      'rocsysApiEnabled',
      'vpnEnabled'
    ]);

    dataToSubmit.deviceOperationsRetentionDays = data.deviceOperationsRetentionEnabled
      ? data.deviceOperationsRetentionDays
      : null;
    dataToSubmit.deviceMeasurementsRetentionDays = data.deviceMeasurementsRetentionEnabled
      ? data.deviceMeasurementsRetentionDays
      : null;
    dataToSubmit.autoLogCollectionEnabled = data.autoLogCollection === AutoLogCollectionOptions.Off ? false : true;
    dataToSubmit.autoLogCollectionOnSuccessEnabled =
      data.autoLogCollection === AutoLogCollectionOptions.All ? true : false;
    dataToSubmit.autoImageCollectionEnabled =
      data.autoImageCollection === AutoImageCollectionOptions.OnFailed ? true : false;

    modifyDeviceCloudSettings({
      variables: dataToSubmit,
      onCompleted: () => {
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        setOpenDeviceCloudSettingsEdit(false);
        setShowConfirmationModal(false);
        reset(data);
      }
    });
  };

  const switchLabels: Pick<RSSwitchProps, 'falseValueLabel' | 'trueValueLabel'> = {
    falseValueLabel: t('deviceDetailsPage.cloudSettingsEdit.switchLabels.disabled'),
    trueValueLabel: t('deviceDetailsPage.cloudSettingsEdit.switchLabels.enabled')
  };

  return (
    <RSDrawer {...props} className="device-cloud-settings-edit">
      <ModalDrawerHeader title={t('deviceDetailsPage.cloudSettingsEdit.drawerTitle')} />
      <form
        data-testid="device-cloud-settings-edit-form"
        className="device-cloud-settings-edit__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="device-cloud-settings-edit__input-fields">
          <Controller
            control={control}
            name="notificationsEnabled"
            render={({ field: { onBlur, onChange, value } }) => (
              <RSSwitch
                formLabel={t('deviceDetailsPage.cloudSettingsEdit.inputLabels.notifications')}
                data-testid="device-cloud-settings-notification-enabled-switch"
                checked={Boolean(value)}
                onBlur={onBlur}
                onChange={onChange}
                {...switchLabels}
              />
            )}
          />
          <div className="device-cloud-settings-edit__columns">
            <Controller
              control={control}
              name="autoLogCollection"
              render={({ field: { onBlur, onChange, value, name } }) => (
                <RSRadioGroup
                  formLabel={t('deviceDetailsPage.cloudSettingsEdit.inputLabels.autoLogCollection')}
                  formLabelProps={{ id: 'automatic-log-collection' }}
                  aria-labelledby="automatic-log-collection"
                  value={value}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                >
                  <RSRadio
                    label={t('deviceDetailsPage.cloudSettingsEdit.autoLogCollectionOptions.off')}
                    value={AutoLogCollectionOptions.Off}
                    data-testid="device-auto-log-collection-off"
                  />
                  <RSRadio
                    label={t('deviceDetailsPage.cloudSettingsEdit.autoLogCollectionOptions.failed')}
                    value={AutoLogCollectionOptions.OnFailed}
                    data-testid="device-auto-log-collection-on-failed"
                  />
                  <RSRadio
                    label={t('deviceDetailsPage.cloudSettingsEdit.autoLogCollectionOptions.all')}
                    value={AutoLogCollectionOptions.All}
                    data-testid="device-auto-log-collection-all"
                  />
                </RSRadioGroup>
              )}
            />
            <Controller
              control={control}
              name="autoImageCollection"
              render={({ field: { onBlur, onChange, value, name } }) => (
                <RSRadioGroup
                  formLabel={t('deviceDetailsPage.cloudSettingsEdit.inputLabels.autoImageCollection')}
                  formLabelProps={{ id: 'automatic-image-collection' }}
                  aria-labelledby="automatic-image-collection"
                  value={value}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                >
                  <RSRadio
                    label={t('deviceDetailsPage.cloudSettingsEdit.autoImageCollectionOptions.off')}
                    value={AutoImageCollectionOptions.Off}
                    data-testid="device-auto-image-collection-off"
                  />
                  <RSRadio
                    label={t('deviceDetailsPage.cloudSettingsEdit.autoImageCollectionOptions.failed')}
                    value={AutoImageCollectionOptions.OnFailed}
                    data-testid="device-auto-image-collection-on-failed"
                  />
                </RSRadioGroup>
              )}
            />
            <Controller
              control={control}
              name="rocsysApiEnabled"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  formLabel={t('deviceDetailsPage.cloudSettingsEdit.inputLabels.rocsysApi')}
                  data-testid="device-cloud-settings-rocsys-api-enabled-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  {...switchLabels}
                />
              )}
            />
            <Controller
              control={control}
              name="vpnEnabled"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  formLabel={t('deviceDetailsPage.cloudSettingsEdit.inputLabels.vpn')}
                  data-testid="device-cloud-settings-vpn-enabled-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  {...switchLabels}
                />
              )}
            />
            <div>
              <Controller
                control={control}
                name="deviceOperationsRetentionEnabled"
                render={({ field: { onChange, onBlur, value } }) => (
                  <RSSwitch
                    formLabel={t('deviceDetailsPage.cloudSettingsEdit.inputLabels.retentionAcdCycles')}
                    data-testid="device-cloud-settings-operations-retention-enabled-switch"
                    formLabelProps={{ focused: retentionAcdCyclesLabelFocused }}
                    {...switchLabels}
                    checked={Boolean(value)}
                    onFocus={() => setRetentionAcdCyclesLabelFocused(true)}
                    onBlur={() => {
                      onBlur();
                      setRetentionAcdCyclesLabelFocused(false);
                    }}
                    onChange={(event) => {
                      onChange(event);
                      if (!event.target.checked) {
                        setValue('deviceOperationsRetentionDays', null);
                        trigger(); // Re-trigger the validation for the form to update the "days" field
                      }
                    }}
                  />
                )}
              />
              <div className="device-cloud-settings-edit__retention-acd-cycles-days">
                <Controller
                  control={control}
                  name="deviceOperationsRetentionDays"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div
                      className="device-cloud-settings-edit__retention-acd-cycles-input-group"
                      data-testid="device-cloud-settings-retention-acd-cycles-input-group"
                    >
                      <div className="device-cloud-settings-edit__retention-acd-cycles-days-input">
                        <span className="device-cloud-settings-edit__days-label">
                          {t('deviceDetailsPage.cloudSettingsEdit.retention.daysLabel')}
                        </span>
                        <RSTextInput
                          className="device-cloud-settings-edit__retention-acd-cycles-input"
                          data-testid="device-cloud-settings-retention-acd-cycles-days-input"
                          type="number"
                          disabled={!watchValues.deviceOperationsRetentionEnabled}
                          error={Boolean(errors.deviceOperationsRetentionDays?.message)}
                          value={value || ''}
                          // https://github.com/orgs/react-hook-form/discussions/8068
                          onChange={(event) => onChange(toNumber(event.target.value))}
                          onBlur={() => {
                            onBlur();
                            setRetentionAcdCyclesLabelFocused(false);
                          }}
                          onFocus={() => setRetentionAcdCyclesLabelFocused(true)}
                        />
                      </div>
                      {errors.deviceOperationsRetentionDays?.message && (
                        <FormHelperText
                          className="rs-text-input__helper-text"
                          error={Boolean(errors.deviceOperationsRetentionDays?.message)}
                        >
                          {errors.deviceOperationsRetentionDays?.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <div>
              <Controller
                control={control}
                name="deviceMeasurementsRetentionEnabled"
                render={({ field: { onChange, onBlur, value } }) => (
                  <RSSwitch
                    formLabel={t('deviceDetailsPage.cloudSettingsEdit.inputLabels.retentionMeasurements')}
                    data-testid="device-cloud-settings-measurements-retention-enabled-switch"
                    formLabelProps={{ focused: retentionMeasurementsLabelFocused }}
                    {...switchLabels}
                    checked={Boolean(value)}
                    onFocus={() => setRetentionMeasurementsLabelFocused(true)}
                    onBlur={() => {
                      onBlur();
                      setRetentionMeasurementsLabelFocused(false);
                    }}
                    onChange={(event) => {
                      onChange(event);
                      if (!event.target.checked) {
                        setValue('deviceMeasurementsRetentionDays', null);
                        trigger(); // Re-trigger the validation for the form to update the "days" field
                      }
                    }}
                  />
                )}
              />
              <div className="device-cloud-settings-edit__retention-measurements-days">
                <Controller
                  control={control}
                  name="deviceMeasurementsRetentionDays"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="device-cloud-settings-edit__retention-measurements-input-group">
                      <div className="device-cloud-settings-edit__retention-measurements-days-input">
                        <span className="device-cloud-settings-edit__days-label">
                          {t('deviceDetailsPage.cloudSettingsEdit.retention.daysLabel')}
                        </span>
                        <RSTextInput
                          className="device-cloud-settings-edit__retention-measurements-input"
                          data-testid="device-cloud-settings-retention-measurements-days-input"
                          type="number"
                          disabled={!watchValues.deviceMeasurementsRetentionEnabled}
                          error={Boolean(errors.deviceMeasurementsRetentionDays?.message)}
                          value={value || ''}
                          // https://github.com/orgs/react-hook-form/discussions/8068
                          onChange={(event) => onChange(toNumber(event.target.value))}
                          onBlur={() => {
                            onBlur();
                            setRetentionMeasurementsLabelFocused(false);
                          }}
                          onFocus={() => setRetentionMeasurementsLabelFocused(true)}
                        />
                      </div>
                      {errors.deviceMeasurementsRetentionDays?.message && (
                        <FormHelperText
                          className="rs-text-input__helper-text"
                          error={Boolean(errors.deviceMeasurementsRetentionDays.message)}
                        >
                          {errors.deviceMeasurementsRetentionDays.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          isSaveDisabled={loading || !isEmpty(errors)}
          isCancelDisabled={loading}
        />
      </form>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        disableConfirmButton={loading}
        disableCancelButton={loading}
      />
    </RSDrawer>
  );
};

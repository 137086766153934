import classNames from 'classnames';
import { JSX, PropsWithChildren } from 'react';

interface DetailsMainSectionWrapperProps extends PropsWithChildren {
  panel: JSX.Element;
  extraClassNames?: string[];
}

export const DetailsMainSectionWrapper = (props: DetailsMainSectionWrapperProps): JSX.Element => {
  const newClassNames = classNames('details-main-section-wrapper', props.extraClassNames);
  return (
    <div className={newClassNames} data-testid="details-main-section-wrapper">
      <div className="details-main-section-wrapper__panel">{props.panel}</div>
      <div className="details-main-section-wrapper__content">{props.children}</div>
    </div>
  );
};

import { OperationalLifeCycle } from '../../__generated__/graphql';
import i18n from '../../i18n';

export type OperationalLifeCycleType = `${OperationalLifeCycle}`;

export const mapOperationalLifeCycleDisplayLabel = (value: OperationalLifeCycleType): string | undefined => {
  switch (value) {
    case OperationalLifeCycle.GeneralTesting:
      return i18n.t('displayLabels.operationalLifeCycle.generalTesting');
    case OperationalLifeCycle.Operational:
      return i18n.t('displayLabels.operationalLifeCycle.operational');
    default:
      return undefined;
  }
};

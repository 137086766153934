import { DataGrid, DataGridProps } from '@mui/x-data-grid';

type RSDataGridProps = DataGridProps;

/**
 * This component renders a RSDataGrid component. It is based on the MUI Datagrid component.
 * The API is the same as the MUI Datagrid component: https://mui.com/x/api/data-grid/data-grid/
 * Only commonly used properties are listed below.
 *
 * @param {GridColDef<unknown>[]} columns Column definitions.
 * @param {GridRowsProp[]} [rows] Row data.
 * @param {boolean} [loading] Displays loading element when true.
 * @returns {React.JSX.Element} A React element that renders a RSDataGrid component.
 */

export const RSDataGrid = (props: RSDataGridProps): JSX.Element => {
  return (
    <DataGrid
      {...props}
      className="rs-data-grid"
      disableColumnFilter={true}
      disableColumnMenu={true}
      disableColumnResize={true}
      disableColumnSelector={true}
      disableColumnSorting={true}
      disableDensitySelector={true}
      disableMultipleRowSelection={true}
      disableRowSelectionOnClick={true}
      hideFooter={true}
      rowHeight={48}
    />
  );
};

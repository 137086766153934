import { gql } from '../../../__generated__/gql';

export const QUERY_GET_PROGRAM_NAMES = gql(`
  query getProgramNames($filters: ProgramBoolExp) {
    programs(orderBy: { name: ASC }, where: $filters) {
      id
      name
    }
  }
`);

import { gql } from '../../../__generated__/gql';

export const MUTATION_REQUEST_DEVICE_IMAGE_UPLOAD_URL = gql(`
  mutation mutationRequestDeviceImageUploadUrl($deviceId: String!, $order: Int!) {
    requestDeviceImageUploadUrl(deviceId: $deviceId, order: $order) {
      id
      url
      order
    }
  }
`);

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_OPERATION_IMAGE = gql(`
  query getOperationImageByPK($imageId: uuid!) {
    deviceOperationImageByPK(id: $imageId) {
      id
      fileName
      size
      status
      type
      visualType
      recordedAt
      contentType
      requestTries
      metaData
    }
  }
`);

import { JSX } from 'react';

interface CodeViewerProps {
  data: string;
}

export const CodeViewer = ({ data }: CodeViewerProps): JSX.Element => {
  return (
    <div className="code-viewer" data-testid="code-viewer">
      <div className="code-viewer__contents">
        <code className="code-viewer__code">{data}</code>
      </div>
    </div>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_ROC_OS_VERSIONS = gql(`
  query getRocOSVersions {
    activeDeviceSoftwareConfigurations(
      orderBy: { rocosComposedVersion: ASC }
      distinctOn: rocosComposedVersion
      where: { rocosComposedVersion: { _isNull: false } }
    ) {
      rocosComposedVersion
      id
    }
  }
`);

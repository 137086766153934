import { GetOperationDetailsQuery } from '../__generated__/graphql';

export type AnnotationAuditHistory = NonNullable<
  GetOperationDetailsQuery['deviceOperationByPK']
>['deviceOperationAnnotationAudits'];

export type OriginalOperationResult = NonNullable<
  GetOperationDetailsQuery['deviceOperationByPK']
>['deviceOperationResult'];

export type InitialOperationalLifeCycle = NonNullable<
  GetOperationDetailsQuery['deviceOperationByPK']
>['initialOperationalLifeCycle'];

export enum AnnotationResult {
  Annotated = 'ANNOTATED',
  NotAnnotated = 'NOT_ANNOTATED'
}

export enum AnnotationStatus {
  Confirmed = 'CONFIRMED',
  Overridden = 'OVERRIDDEN',
  NotAnnotated = 'NOT_ANNOTATED'
}

export interface AnnotationStatusInterface {
  annotationStatus: AnnotationStatus;
}

export enum OperationPeriod {
  Last90Days = 'LAST_90_DAYS',
  Last30Days = 'LAST_30_DAYS',
  Last7Days = 'LAST_7_DAYS',
  FromStart = 'FROM_START',
  CustomRange = 'CUSTOM_RANGE'
}

import { useMutation } from '@apollo/client';
import { Uppy } from '@uppy/core';
import { Dashboard } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { useSnackbar } from 'notistack';
import { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteImpressionImage } from './site-impression-image';
import { graphqlApiConfig } from '../../../configs';
import { MUTATION_REQUEST_DEVICE_IMAGE_UPLOAD_URL, MUTATION_UPSERT_DEVICE_IMAGE } from '../../../services/mutations';
import { SnackbarMessageType } from '../../../types';
import { constructSnackbarMessage } from '../../../utilities';

interface SiteImpressionProps {
  imageId?: string;
  deviceId: string;
  canUserEditDevice?: boolean;
}

export const SiteImpression = ({ imageId, deviceId, canUserEditDevice }: SiteImpressionProps): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };
  const [deviceImageId, setDeviceImageId] = useState<string | undefined>(imageId);
  const [awsUploadUrl, setAwsUploadUrl] = useState<string | undefined>(undefined);
  const [readyToUpload, setReadyToUpload] = useState<boolean>(false);
  const [requestUrl] = useMutation(MUTATION_REQUEST_DEVICE_IMAGE_UPLOAD_URL, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const [upsertDeviceImage] = useMutation(MUTATION_UPSERT_DEVICE_IMAGE, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/png', 'image/jpeg'],
        // 4 MB
        maxFileSize: 4096000
      },
      autoProceed: false
    })
      .use(XHRUpload, {
        // The endpoint can only be updated if the file gets added. Therefore you can find a `setAwsUploadUrl` state
        // update hook in `.on('file-added')`. The value of the endpoint is then updated by a `useEffect()` hook.
        // See: https://uppy.io/docs/react/#example-updating-uppys-options-dynamically-based-on-props
        endpoint: '',
        method: 'PUT',
        timeout: 10000,
        // By default XHRUpload sends a form <input type="file" /> to the server. It needs to be explicitly turned off.
        formData: false,
        headers: (file) => ({
          'content-type': file.type || ''
        }),
        // XHRUpload tries to parse the response of the upload endpoint as JSON by default.
        // See: https://github.com/transloadit/uppy/issues/594#issuecomment-363114743
        getResponseData: () => {
          return {};
        }
      })
      .on('file-added', (file) => {
        requestUrl({
          // for now there is only one image per device, so order is always 0
          variables: { deviceId, order: 0 },
          onCompleted: (data) => {
            // The `endpoint` field for XHRUpload cannot read data based on file (unlike `headers`). Therefore the
            // update is done through useState() and useEffect().
            setAwsUploadUrl(data?.requestDeviceImageUploadUrl?.url);
            uppy.setFileMeta(file.id, { imageId: data.requestDeviceImageUploadUrl?.id });
          },
          onError: (error) => {
            sendMessageToSnackbar(
              t('deviceDetailsPage.sidePanel.siteImpression.errors.deviceImageRequestUploadUrl'),
              error.name,
              error.message,
              'error'
            );
          }
        });
      })
      .on('upload-success', (file) => {
        setAwsUploadUrl(undefined);
        setReadyToUpload(false);
        upsertDeviceImage({
          variables: {
            // Cannot use `imageToUploadId`: the uppy object is generated before the file is added.
            // To allow using a reference to the file, metadata for the file is needed.
            id: (file?.meta.imageId as string) || '',
            deviceId,
            fileName: file?.name || 'upload-image',
            order: 0,
            generateThumbnail: true
          },
          onCompleted: () => {
            setDeviceImageId(file?.meta.imageId as string);
            sendMessageToSnackbar(
              t('deviceDetailsPage.sidePanel.siteImpression.success.imageUploaded'),
              undefined,
              undefined,
              'success'
            );
            uppy.cancelAll();
          },
          onError: (error) => {
            sendMessageToSnackbar(
              t('deviceDetailsPage.sidePanel.siteImpression.errors.deviceImageUpsert'),
              error.name,
              error.message,
              'error'
            );
          }
        });
      })
      .on('error', (error) => {
        setAwsUploadUrl(undefined);
        setReadyToUpload(false);
        sendMessageToSnackbar(
          t('deviceDetailsPage.sidePanel.siteImpression.errors.deviceImageRequestUploadUrl'),
          error.name,
          error.message,
          'error'
        );
      })
      .on('cancel-all', () => {
        setAwsUploadUrl(undefined);
        setReadyToUpload(false);
      })
  );

  useEffect(() => {
    if (awsUploadUrl) {
      uppy.getPlugin('XHRUpload')?.setOptions({ endpoint: awsUploadUrl });
      setReadyToUpload(true);
    }
  }, [awsUploadUrl, uppy]);

  useEffect(() => {
    if (readyToUpload) {
      uppy.upload();
    }
  }, [readyToUpload]);

  if (canUserEditDevice && !deviceImageId) {
    return (
      <div className="site-impression" data-testid="site-impression">
        <Dashboard
          uppy={uppy}
          proudlyDisplayPoweredByUppy={false}
          showSelectedFiles={true}
          showProgressDetails={true}
        />
        <div className="site-impression__upload-prompt">
          <span className="site-impression__formats">
            {t('deviceDetailsPage.sidePanel.siteImpression.supportedFormats')}
          </span>
          <span className="site-impression__max-size">
            {t('deviceDetailsPage.sidePanel.siteImpression.maxFileSize')}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="site-impression" data-testid="site-impression">
      <SiteImpressionImage
        deviceId={deviceId}
        deviceImageId={deviceImageId}
        setDeviceImageId={setDeviceImageId}
        deletable={Boolean(canUserEditDevice)}
      />
    </div>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_OPERATION_LOG_URLS = gql(`
  query getOperationLogUrls($ids: [String!]!) {
    requestDeviceOperationLogUrls(ids: $ids, forDownload: true) {
      url
      id
    }
  }
`);

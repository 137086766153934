import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { CountBlock } from '../../../../5-elements';

export interface CustomerCountBlocksProps {
  devices: number;
  sites: number;
  programs: number;
}

export const CustomerCountBlocks = ({ devices, sites, programs }: CustomerCountBlocksProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <section className="customer-count-blocks" data-testid="customer-count-blocks">
      <CountBlock
        title={t('customerDetailsPage.sidePanel.countBlocks.devices')}
        count={devices}
        data-testid="customer-count-devices"
      />
      <CountBlock
        title={t('customerDetailsPage.sidePanel.countBlocks.sites')}
        count={sites}
        data-testid="customer-count-sites"
      />
      <CountBlock
        title={t('customerDetailsPage.sidePanel.countBlocks.programs')}
        count={programs}
        data-testid="customer-count-programs"
      />
    </section>
  );
};

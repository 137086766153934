import { isNil } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { GetOperationResultsQuery } from '../../../../__generated__/graphql';
import CheckMarkCircleIcon from '../../../../assets/icons/check-mark-circle.svg?react';
import CrossCircleIcon from '../../../../assets/icons/cross-circle.svg?react';
import InProgressLargeIcon from '../../../../assets/icons/in-progress-large.svg?react';
import { Flatten } from '../../../../types';

interface OperationResultOptionProps {
  resultObject?: Flatten<GetOperationResultsQuery['deviceOperationResults']>;
}

export const OperationResultOption = ({ resultObject }: OperationResultOptionProps): JSX.Element => {
  const { t } = useTranslation();

  const readableResult = resultObject?.name;
  const isSuccessful = resultObject?.isSuccessful;

  if (readableResult === 'Revert') {
    return (
      <span className="operation-result-display-option" data-testid="operation-result-display-option-revert">
        <div className="operation-result-display-option__result">{t('operationsPage.results.revert')}</div>
      </span>
    );
  }

  if (isNil(isSuccessful)) {
    return (
      <span
        className="operation-result-display-option operation-result-display-option--in-progress"
        data-testid="operation-result-display-option-in-progress"
      >
        <div className="operation-result-display-option__icon-wrapper">
          <InProgressLargeIcon className="operation-result-display-option__icon" />
        </div>
        <div className="operation-result-display-option__result">{t('operationsPage.results.inProgress')}</div>
      </span>
    );
  }

  if (isSuccessful === true) {
    return (
      <span
        className="operation-result-display-option operation-result-display-option--success"
        data-testid="operation-result-display-option-success"
      >
        <div className="operation-result-display-option__icon-wrapper">
          <CheckMarkCircleIcon className="operation-result-display-option__icon" />
        </div>
        <div className="operation-result-display-option__result">{readableResult}</div>
      </span>
    );
  }

  return (
    <span
      className="operation-result-display-option operation-result-display-option--failed"
      data-testid="operation-result-display-option-failed"
    >
      <div className="operation-result-display-option__icon-wrapper">
        <CrossCircleIcon className="operation-result-display-option__icon" />
      </div>
      <div className="operation-result-display-option__result">{readableResult}</div>
    </span>
  );
};

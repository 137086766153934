import classNames from 'classnames';
import { ComponentProps, JSX, ReactNode } from 'react';

import { DataTestId } from '../../../types';

interface InformationBlockProps extends DataTestId {
  label?: ReactNode;
  value?: ReactNode;
  valueProps?: ComponentProps<'div'>;
  subValue?: ReactNode;
  extraClassNames?: string[];
}

/**
 * This component renders an InformationBlock component. The component has three parts: label (title), value and
 * a sub value.
 * @param {React.ReactNode} [label] The label of the information block.
 * @param {React.ReactNode} [value] The main value/information for the block.
 * @param {React.ReactNode} [valueProps] The properties for the `value` <div> component.
 * @param {React.ReactNode} [subValue] The sub-value of the component.
 * @param {string} [dataTestId] The test ID.
 * @param {string[]} [extraClassNames] Extra class names for the information block.
 * @returns {JSX.Element} An InformationBlock component.
 */
export const InformationBlock = (props: InformationBlockProps): JSX.Element => {
  const componentClassNames = classNames('information-block', props.extraClassNames);
  return (
    <div className={componentClassNames} data-testid={props['data-testid']}>
      {props.label && <div className="information-block__label">{props.label}</div>}
      {props.value && (
        <div {...props.valueProps} className="information-block__value">
          {props.value}
        </div>
      )}
      {props.subValue && <div className="information-block__sub-value">{props.subValue}</div>}
    </div>
  );
};

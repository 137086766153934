import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_ONLINE_STATUS = gql(`
  query getDeviceOnlineStatus($deviceId: uuid!) {
    deviceByPK(id: $deviceId) {
      id
      deviceMeasurementValues(
        where: { deviceMeasurementProperty: { name: { _in: ["is_online"] } }, endAt: { _isNull: true } }
      ) {
        id
        value
        updatedAt
        status
        deviceMeasurementProperty {
          id
          name
          unit
        }
      }
    }
  }
`);

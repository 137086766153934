import classNames from 'classnames';
import { JSX } from 'react';

import OfflineIcon from '../../../assets/icons/offline.svg?react';
import AttentionRequiredIcon from '../../../assets/icons/status-attention-required.svg?react';
import CriticalIcon from '../../../assets/icons/status-critical.svg?react';
import ImprovementPossibleIcon from '../../../assets/icons/status-improvement-possible.svg?react';
import MaintenanceRequiredIcon from '../../../assets/icons/status-maintenance-required.svg?react';
import NoMonitoringActiveIcon from '../../../assets/icons/status-no-monitoring-active.svg?react';
import StatusOkIcon from '../../../assets/icons/status-ok.svg?react';
import { DeviceStatusIconVariant } from '../../../types';

export interface DeviceStatusIconProps {
  statusIconVariant?: DeviceStatusIconVariant;
  extraClassNames?: string;
}

export const DeviceStatusIcon = ({ statusIconVariant, extraClassNames }: DeviceStatusIconProps): JSX.Element => {
  const combinedClassNames = classNames(
    'device-status-icon',
    `device-status-icon--${statusIconVariant}`,
    extraClassNames
  );
  return (
    <div className={combinedClassNames} data-testid="device-status-icon">
      <DeviceStatusIconContent statusIconVariant={statusIconVariant} />
    </div>
  );
};

const DeviceStatusIconContent = ({ statusIconVariant }: DeviceStatusIconProps): JSX.Element => {
  switch (statusIconVariant) {
    case 'normal':
      return <StatusOkIcon data-testid="device-status-icon-ok" />;
    case 'attention-required':
      return <AttentionRequiredIcon data-testid="device-status-icon-attention-required" />;
    case 'critical':
      return <CriticalIcon data-testid="device-status-icon-critical" />;
    case 'improvement-possible':
      return <ImprovementPossibleIcon data-testid="device-status-icon-improvement-possible" />;
    case 'maintenance-required':
      return <MaintenanceRequiredIcon data-testid="device-status-icon-maintenance-required" />;
    case 'offline':
      return <OfflineIcon data-testid="device-status-icon-offline" />;
    default:
      return <NoMonitoringActiveIcon data-testid="device-status-icon-no-monitoring-active" />;
  }
};

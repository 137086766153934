import { isNil } from 'lodash';
import { z } from 'zod';

import i18n from '../../../../../i18n';

export enum AutoLogCollectionOptions {
  Off = 'OFF',
  OnFailed = 'ON_FAILED',
  All = 'All'
}

export enum AutoImageCollectionOptions {
  Off = 'OFF',
  OnFailed = 'ON_FAILED'
}

export const mapAutoLogCollectionValuesToRadioOption = (
  autoLogCollectionEnabled?: boolean,
  autoLogCollectionOnSuccessEnabled?: boolean
): AutoLogCollectionOptions => {
  if (autoLogCollectionEnabled && autoLogCollectionOnSuccessEnabled) {
    return AutoLogCollectionOptions.All;
  }
  if (autoLogCollectionEnabled) {
    return AutoLogCollectionOptions.OnFailed;
  }
  return AutoLogCollectionOptions.Off;
};

export const mapAutoImageCollectionValueToRadioOption = (
  autoImageCollectionEnabled?: boolean
): AutoImageCollectionOptions => {
  return autoImageCollectionEnabled ? AutoImageCollectionOptions.OnFailed : AutoImageCollectionOptions.Off;
};

// The schema for the form
const deviceCloudSettingsEditSchema = z.object({
  deviceId: z.string().uuid(),
  notificationsEnabled: z.boolean().optional(),
  autoLogCollection: z.nativeEnum(AutoLogCollectionOptions),
  autoImageCollection: z.nativeEnum(AutoImageCollectionOptions),
  rocsysApiEnabled: z.boolean().optional(),
  vpnEnabled: z.boolean().optional(),
  deviceOperationsRetentionEnabled: z.boolean().optional(),
  deviceMeasurementsRetentionEnabled: z.boolean().optional(),
  deviceOperationsRetentionDays: z.number().int().gte(1).nullish(),
  deviceMeasurementsRetentionDays: z.number().int().gte(1).nullish()
});

// The refined schema for form validation
// If the retention is disabled, the validation will not be performed on the days input.
// If the retention is enabled, the validation will be performed.
export const deviceCloudSettingsEditValidationSchema = deviceCloudSettingsEditSchema
  .refine(
    (schema) => {
      if (!schema.deviceOperationsRetentionEnabled) {
        return true;
      }
      if (!isNil(schema.deviceOperationsRetentionDays)) {
        return true;
      }
      return false;
    },
    {
      message: i18n.t('forms.inputValidation.belowMinimum', { minLimit: 1 }),
      path: ['deviceOperationsRetentionDays']
    }
  )
  .refine(
    (schema) => {
      if (!schema.deviceMeasurementsRetentionEnabled) {
        return true;
      }
      if (!isNil(schema.deviceMeasurementsRetentionDays)) {
        return true;
      }
      return false;
    },
    {
      message: i18n.t('forms.inputValidation.belowMinimum', { minLimit: 1 }),
      path: ['deviceMeasurementsRetentionDays']
    }
  );

// The data schema to the GraphQL server request
export const deviceCloudSettingsQuerySchema = deviceCloudSettingsEditSchema
  .pick({
    deviceId: true,
    notificationsEnabled: true,
    rocsysApiEnabled: true,
    vpnEnabled: true,
    deviceOperationsRetentionDays: true,
    deviceMeasurementsRetentionDays: true
  })
  .extend({
    autoLogCollectionEnabled: z.boolean().optional(),
    autoLogCollectionOnSuccessEnabled: z.boolean().optional(),
    autoImageCollectionEnabled: z.boolean().optional()
  });

export type DeviceCloudSettingsEditRequest = z.infer<typeof deviceCloudSettingsEditValidationSchema>;
export type DeviceCloudSettingsQueryRequest = z.infer<typeof deviceCloudSettingsQuerySchema>;

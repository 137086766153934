import { ReactNode } from 'react';

interface ModalDrawerFooterProps {
  informationBlocks?: ReactNode;
  actions?: ReactNode;
}

export const ModalDrawerFooter = ({ informationBlocks, actions }: ModalDrawerFooterProps): JSX.Element => {
  return (
    <div className="modal-drawer-footer" data-testid="modal-drawer-footer">
      <div className="modal-drawer-footer__information-blocks">{informationBlocks}</div>
      <div className="modal-drawer-footer__actions">{actions}</div>
    </div>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_SITE_TIMEZONES = gql(`
  query getSiteTimezones {
    sites(distinctOn: timezoneName, orderBy: {timezoneName: ASC}) {
      id
      timezone {
        name
        timezoneInfo {
          abbrev
          isDst
          name
          utcOffset
        }
      }
    }
  }
`);

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

interface UserMenuAvatarProps {
  imageUrl?: string;
}

export const UserMenuAvatar = (props: UserMenuAvatarProps): JSX.Element => {
  if (props.imageUrl) {
    return <img src={props.imageUrl} className="user-menu__avatar" data-testid="user-menu-avatar-image" />;
  }
  return (
    <div className="user-menu__avatar">
      <AccountCircleOutlinedIcon className="user-menu__avatar-placeholder" data-testid="user-menu-avatar-placeholder" />
    </div>
  );
};

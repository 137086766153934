import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import AngleBracketDownIcon from '../../../assets/icons/angle-brackets-down.svg?react';
import { RSHelperTextProps, RSInputLabelProps, RSMenuItemProps } from '../../../types';

export interface RSSelectItemProps {
  displayName: ReactNode;
  menuItemProps: RSMenuItemProps;
}

export type RSSelectProps = SelectProps & {
  helperText?: ReactNode;
  helperTextProps?: RSHelperTextProps;
  inputLabel?: ReactNode;
  inputLabelProps?: RSInputLabelProps;
  menuItems?: RSSelectItemProps[];
};

const DEFAULT_INPUT_LABEL_CLASS_NAME = 'rs-select__input-label';
const DEFAULT_HELPER_TEXT_CLASS_NAME = 'rs-select__helper-text';
const DEFAULT_SELECT_CLASS_NAME = 'rs-select__select';
const DEFAULT_MENU_ITEM_CLASS_NAME = 'rs-select__menu-item';

/**
 * This component renders a RSSelect component. It is based on the MUI Select component.
 * The API is the same as the MUI Select component: https://mui.com/material-ui/api/select/
 * Only commonly used properties are listed below.
 *
 * @param {string} [className] Optional class name for the field.
 * @param {boolean} [required] Whether the field is required.
 * @param {ReactNode} [inputLabel] The input label for the field.
 * @param {ReactNode} [helperText] The helper text for the field.
 * @param {boolean} [disabled] Whether the field is disabled.
 * @param {boolean} [error] Whether the field has error.
 * @returns {React.JSX.Element} A React element that renders a RSSelect component.
 */
export const RSSelect = forwardRef(
  ({ inputLabel, inputLabelProps, menuItems, helperText, helperTextProps, ...props }: RSSelectProps, ref) => {
    const { t } = useTranslation();
    return (
      <FormControl className="rs-select__form-control" data-testid="rs-select-form-control">
        {inputLabel && (
          <InputLabel
            {...inputLabelProps}
            shrink={true}
            className={classNames(DEFAULT_INPUT_LABEL_CLASS_NAME, inputLabelProps?.className)}
          >
            {inputLabel} {props.required && t('input.required')}
          </InputLabel>
        )}
        <Select
          {...props}
          inputRef={ref}
          className={classNames(DEFAULT_SELECT_CLASS_NAME, props.className)}
          IconComponent={AngleBracketDownIcon}
        >
          {menuItems &&
            menuItems.map((menuItem) => (
              <MenuItem
                {...menuItem.menuItemProps}
                key={(menuItem.menuItemProps.value as string | number) || 'nullish'}
                value={menuItem.menuItemProps.value}
                className={classNames(DEFAULT_MENU_ITEM_CLASS_NAME, menuItem.menuItemProps.className)}
              >
                {menuItem.displayName}
              </MenuItem>
            ))}
        </Select>
        {helperText && (
          <FormHelperText
            {...helperTextProps}
            className={classNames(DEFAULT_HELPER_TEXT_CLASS_NAME, helperTextProps?.className)}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
RSSelect.displayName = 'RSSelect';

import { Modal, ModalProps } from '@mui/material';

import { BlobModalLoading } from './blob-modal-loading';

export interface BlobModalBaseProps extends ModalProps {
  isLoading?: boolean;
}

export const BlobModalBase = ({ children, isLoading, ...props }: BlobModalBaseProps): JSX.Element => {
  return (
    <Modal {...props} className="blob-modal-base">
      {/* 
        Need to wrap it into a React Fragment, or the error will occur:
        Failed prop type: Invalid prop children supplied to ForwardRef(Tooltip). Expected an element that can hold a ref
      */}
      <>{isLoading ? <BlobModalLoading /> : <div className="blob-modal-base__box">{children}</div>}</>
    </Modal>
  );
};

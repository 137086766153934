import { gql } from '../../../__generated__/gql';

export const QUERY_GET_CONNECTOR_TYPES = gql(`
  query getConnectorTypes {
    connectorHolderTypes(distinctOn: connectorType) {
      id
      connectorType
    }
  }
`);

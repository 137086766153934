import { isEmpty } from 'lodash';

import i18n from '../../../../../i18n';
import { AnnotationAuditHistory, InitialOperationalLifeCycle, OriginalOperationResult } from '../../../../../types';
import { formatTimestamp, getNonNullishDisplayValue } from '../../../../../utilities';

export type ChangeHistoryEntry = {
  id: string;
  timestamp: string;
  annotatedBy: string;
  result?: string;
  remark?: string | null;
  operationalLifeCycle?: string | null;
};

export const generateAnnotatedByName = (firstName?: string, lastName?: string): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName || lastName) {
    return `${firstName || lastName}`;
  }

  return getNonNullishDisplayValue(undefined) as string;
};

export const generateAnnotationChangeHistory = (
  serialNumber: string,
  auditHistory: AnnotationAuditHistory,
  originalResult?: OriginalOperationResult,
  originalOperationalLifeCycle?: InitialOperationalLifeCycle,
  userTimezone?: string
): ChangeHistoryEntry[] => {
  if (!originalResult || isEmpty(auditHistory)) {
    return [];
  }

  const originalEntry: ChangeHistoryEntry[] = [
    {
      id: originalResult.id,
      timestamp: formatTimestamp(originalResult.updatedAt, userTimezone),
      result: originalResult.name,
      operationalLifeCycle: originalOperationalLifeCycle,
      annotatedBy: serialNumber
    }
  ];

  const annotationHistory: ChangeHistoryEntry[] = auditHistory.map((historyEntry, index) => {
    const commonAnnotationEntry: ChangeHistoryEntry = {
      id: historyEntry.id,
      timestamp: formatTimestamp(historyEntry.annotatedAt, userTimezone),
      annotatedBy: generateAnnotatedByName(
        historyEntry.annotatedByUser?.firstName,
        historyEntry.annotatedByUser?.lastName
      )
    };

    // The annotation history is sorted descendent. If it is the last audit entry, we compare it with the original
    // result.
    if (index === auditHistory.length - 1) {
      return {
        ...commonAnnotationEntry,
        result:
          historyEntry.deviceOperationResult?.name === originalResult.name
            ? i18n.t('operationsPage.operationDetails.annotationHistory.confirmed', {
                operationResult: historyEntry.deviceOperationResult?.name
              })
            : historyEntry.deviceOperationResult?.name,
        remark: historyEntry.remark,
        // This is the only case where we can detect whether the operational life cycle has been changed or not, because
        // the original value of operational life cycle is never saved.
        operationalLifeCycle:
          historyEntry.operationalLifeCycle === originalOperationalLifeCycle
            ? undefined
            : historyEntry.operationalLifeCycle
      };
    }

    return {
      ...commonAnnotationEntry,
      result:
        historyEntry.deviceOperationResult?.name === auditHistory[index + 1].deviceOperationResult?.name
          ? undefined
          : historyEntry.deviceOperationResult?.name,
      remark: historyEntry.remark === auditHistory[index + 1].remark ? undefined : historyEntry.remark,
      operationalLifeCycle:
        historyEntry.operationalLifeCycle === auditHistory[index + 1].operationalLifeCycle
          ? undefined
          : historyEntry.operationalLifeCycle
    };
  });

  return [...originalEntry, ...annotationHistory];
};

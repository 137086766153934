import { zodResolver } from '@hookform/resolvers/zod';
import { filter, includes, omit, pick } from 'lodash';
import qs from 'qs';
import { JSX, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { GetCustomerNamesQuery } from '../../../../__generated__/graphql';
import { filterValidUrlFields } from '../../../../utilities';
import { FilterPanelButtonsGroup } from '../../../4-features';
import { RSAutocomplete } from '../../../5-elements';
import { CustomersOverviewSearchParameters, customersOverviewStatesSchema } from '../customers-overview-states-schema';
import { customersFilterFields } from '../generate-queries';

interface CustomersFilterPanelProps {
  customerNames?: GetCustomerNamesQuery;
  defaultValues: CustomersOverviewSearchParameters;
}

export const CustomersFilterPanel = ({ customerNames, defaultValues }: CustomersFilterPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    getValues,
    reset
  } = useForm<CustomersOverviewSearchParameters>({
    resolver: zodResolver(customersOverviewStatesSchema),
    defaultValues
  });

  const customerNameOptions = customerNames?.customers.map((customer) => customer.company.name) || [];

  const onSubmit: SubmitHandler<CustomersOverviewSearchParameters> = (data): void => {
    const newSearchParams = {
      ...qs.parse(searchParams.toString()),
      ...data,
      page: '1'
    };
    setSearchParams(new URLSearchParams(qs.stringify(newSearchParams, { arrayFormat: 'brackets' })));
    reset(data);
  };

  const onReset: SubmitHandler<CustomersOverviewSearchParameters> = (): void => {
    const searchParamsObject = qs.parse(searchParams.toString());
    const searchParamsObjectNoFilter = omit(searchParamsObject, customersFilterFields);
    searchParamsObjectNoFilter.page = '1';
    setSearchParams(new URLSearchParams(qs.stringify(searchParamsObjectNoFilter, { arrayFormat: 'brackets' })));
    reset({ name: [] });
  };

  useEffect(() => {
    const searchParameters = qs.parse(searchParams.toString());
    const validUrlFields = filterValidUrlFields<CustomersOverviewSearchParameters>(
      searchParameters,
      customersOverviewStatesSchema
    );
    const filterParameters = pick(validUrlFields, customersFilterFields);
    if (getValues('name') !== filterParameters.name) {
      reset({ name: filterParameters.name || [] });
    }
  }, [routerLocation.search]);

  return (
    <aside className="customers-filter-panel" data-testid="customers-filter-panel">
      <form className="customers-filter-panel__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="customers-filter-panel__filters">
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('customersPage.filterPanel.customerName')}
                  data-testid="customers-name-autocomplete"
                  options={customerNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(customerNameOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </div>
        <FilterPanelButtonsGroup
          handleApply={handleSubmit(onSubmit)}
          handleClearAll={handleSubmit(onReset)}
          isApplyDisabled={!isDirty}
        />
      </form>
    </aside>
  );
};

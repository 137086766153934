import { z } from 'zod';

import { OperationalLifeCycle } from '../../../../../__generated__/graphql';

export const mainOperationEditSchema = z.object({
  id: z.string().uuid(),
  annotatedResultCode: z.string().nullish(),
  operationalLifeCycle: z.nativeEnum(OperationalLifeCycle).nullish(),
  remark: z.string().nullish()
});

export type MainOperationEditRequest = z.infer<typeof mainOperationEditSchema>;

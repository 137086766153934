import { JSX } from 'react';

import CheckIcon from '../../../../assets/icons/check-mark.svg?react';
import CrossIcon from '../../../../assets/icons/cross.svg?react';
import InProgressIcon from '../../../../assets/icons/in-progress.svg?react';
import { DataTestId } from '../../../../types';
import { RSChip, RSChipProps, RSTooltip, RSTooltipProps } from '../../../5-elements';

export interface OperationResultChipProps {
  success?: boolean | null;
  hasEndedAt: boolean;
  title: RSTooltipProps['title'];
  label: RSChipProps['label'];
}

interface OperationResultChipBaseProps extends DataTestId {
  title: RSTooltipProps['title'];
  label: RSChipProps['label'];
  icon: RSChipProps['icon'];
  color: RSChipProps['color'];
}

const OperationResultChipBase = ({
  title,
  label,
  icon,
  color,
  'data-testid': dataTestId
}: OperationResultChipBaseProps) => {
  return (
    <RSTooltip title={title} className="operation-result-chip">
      <div>
        <RSChip
          label={label}
          icon={icon}
          color={color}
          data-testid={dataTestId ? `operation-result-chip-${dataTestId}` : 'operation-result-chip'}
        />
      </div>
    </RSTooltip>
  );
};

export const OperationResultChip = ({ title, label, hasEndedAt, success }: OperationResultChipProps): JSX.Element => {
  if (hasEndedAt && success === true) {
    return (
      <OperationResultChipBase title={title} label={label} icon={<CheckIcon />} color="success" data-testid="success" />
    );
  }

  if (hasEndedAt && success === false) {
    return (
      <OperationResultChipBase title={title} label={label} icon={<CrossIcon />} color="error" data-testid="error" />
    );
  }

  return (
    <OperationResultChipBase
      title={title}
      label={label}
      icon={<InProgressIcon />}
      color="default"
      data-testid="default"
    />
  );
};

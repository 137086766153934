import { isNil } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { generateGoogleMapsUrl } from '../../../utilities';

interface SiteCardLocationLinkProps {
  address?: string | undefined | null;
  latitude?: number;
  longitude?: number;
}

export const SiteCardLocationLink = (props: SiteCardLocationLinkProps): JSX.Element => {
  const { t } = useTranslation();

  if (isNil(props.latitude) || isNil(props.longitude)) {
    return (
      <div className="site-card-location-link__unavailable" data-testid="site-card-location-link-unavailable">
        {t('siteCard.unavailable')}
      </div>
    );
  }

  const linkText = props.address ? props.address : t('siteCard.showOnMap');

  return (
    <div className="site-card-location-link" data-testid="site-card-location-link">
      <a
        className="site-card-location-link__text"
        href={generateGoogleMapsUrl(props.latitude, props.longitude)}
        target="_blank"
        rel="noreferrer"
        title={linkText}
      >
        {linkText}
      </a>
    </div>
  );
};

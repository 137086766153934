import { isNaN, isNil } from 'lodash';
import { z } from 'zod';

import i18n from '../../../../../i18n';

export const deviceDetailsEditSchema = z.object({
  id: z.string().uuid(),
  deviceTypeId: z.string().uuid({ message: i18n.t('forms.inputValidation.emptyInput') }),
  connectorHolderTypeId: z.string().uuid().optional(),
  /*
    The validation of `location` object:
    1. Business logic: `location` is optional.
      - if the request sends `location: null` to the server, the backend will remove the location object.
      - if the request sends `location: undefined` to the server, the backend will ignore the `location` field.
    2. Based on the business logic:
      - If both `latitude` or `longitude` are NaN or null (empty input), the `location` object becomes `null`.
        This is achieved by `.transform()`.
      - After the transformation (the sequence of method chaining matters), if either of latitude or longitude is NaN,
        an validation error will be shown. This means: it is valid if both longitude and latitude are filled in,
        or both are not filled in.
    3. Each input field must have a value. For the latitude and longitude, they can be both `null`, as parsing NaN
       directly will give a console warning. However, if the user enters a value and later deletes it in a number input,
       it will be NaN. Therefore we need to handle both NaN and null.
    superRefine: https://stackoverflow.com/questions/77167264/display-error-messages-in-multiple-locations-using-zod
  */
  location: z
    .object({
      latitude: z
        .union([
          z
            .number({
              required_error: i18n.t('forms.inputValidation.emptyInput'),
              invalid_type_error: i18n.t('forms.inputValidation.noCharsAllowed')
            })
            .gte(-90, { message: i18n.t('forms.inputValidation.outOfRange.latitude') })
            .lte(90, { message: i18n.t('forms.inputValidation.outOfRange.latitude') }),
          z.nan()
        ])
        .nullish(),
      longitude: z
        .union([
          z
            .number({
              required_error: i18n.t('forms.inputValidation.emptyInput'),
              invalid_type_error: i18n.t('forms.inputValidation.noCharsAllowed')
            })
            .gte(-180, { message: i18n.t('forms.inputValidation.outOfRange.latitude') })
            .lte(180, { message: i18n.t('forms.inputValidation.outOfRange.latitude') }),
          z.nan()
        ])
        .nullish()
    })
    .transform((location) => {
      const isLatitudeNullishOrNaN = isNil(location.latitude) || isNaN(location.latitude);
      const isLongitudeNullishOrNaN = isNil(location.longitude) || isNaN(location.longitude);

      if (isLatitudeNullishOrNaN && isLongitudeNullishOrNaN) {
        return null;
      }

      return location;
    })
    .superRefine((location, context) => {
      if (location !== null && (isNil(location.latitude) || isNaN(location.latitude))) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['latitude'],
          fatal: true,
          message: i18n.t('forms.inputValidation.emptyInput')
        });
      }
      if (location !== null && (isNil(location.longitude) || isNaN(location.longitude))) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['longitude'],
          fatal: true,
          message: i18n.t('forms.inputValidation.emptyInput')
        });
      }
    })
    .nullish(),
  description: z
    .string()
    .max(250, { message: i18n.t('forms.inputValidation.maxLengthExceeded', { maxLength: '250' }) })
    .nullish()
});

export type DeviceDetailsEditRequest = z.infer<typeof deviceDetailsEditSchema>;

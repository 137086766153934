import { JSX } from 'react';

import { Loading } from '../../5-elements';

// A full-screen loading page
export const LoadingPage = (): JSX.Element => {
  return (
    <section className="loading-page" data-testid="loading-page">
      <Loading />
    </section>
  );
};

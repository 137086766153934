import classNames from 'classnames';
import { JSX } from 'react';

import { RSNavLinkTimezone, RSNavLinkTimezoneProps } from '../../4-features';

export interface MainNavMenuButtonProps extends RSNavLinkTimezoneProps {
  label: string;
  icon: JSX.Element;
}

export const MainNavMenuButton = ({ label, icon, ...props }: MainNavMenuButtonProps): JSX.Element => {
  const menuButtonClassNames = (isActive: boolean): string => {
    return classNames({
      'main-nav-menu-button': true,
      'main-nav-menu-button--active': isActive === true
    });
  };

  return (
    <RSNavLinkTimezone
      data-testid="main-nav-menu-button"
      {...props}
      className={({ isActive }) => menuButtonClassNames(isActive)}
    >
      <div className="main-nav-menu-button__icon-container">{icon}</div>
      <p className="main-nav-menu-button__label">{label}</p>
    </RSNavLinkTimezone>
  );
};

import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';
import { JSX } from 'react';

export interface RSButtonProps extends ButtonProps {
  extraClassNames?: string[];
}

/**
 * This component renders a RSButton component. It is based on the MUI Button component.
 * The API is the same as the MUI Button component: https://mui.com/material-ui/api/button/
 * Only commonly used properties are listed below.
 *
 * @param {string} [color=default] The color of the button. RSButton supports 'default', 'warning', 'error' and
 *   'success'.
 * @param {'contained' | 'outlined' | 'text'} [variant=text] The variant of the button.
 * @param {string[]} [extraClassNames] Extra class names for RSButton.
 * @param {React.ReactNode} [children] The children of the <RSButton> component.
 * @returns {React.JSX.Element} A React element that renders a RSButton component.
 */
export const RSButton = ({ children, extraClassNames, ...props }: RSButtonProps): JSX.Element => {
  const buttonClassNames = classNames(
    'rs-button',
    { 'rs-button--disabled': props.disabled === true },
    extraClassNames || []
  );

  return (
    <Button {...props} className={buttonClassNames}>
      {children}
    </Button>
  );
};

import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { head } from 'lodash';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { CustomersPageContent } from './customers-page-content';
import { appConfig } from '../../../configs';
import { QUERY_GET_USER_AUTH_INFO } from '../../../services/queries';
import { UserTimezoneContext } from '../../contexts';
import { ErrorPage } from '../error-page';
import { LoadingPage } from '../loading-page';

export const CustomersPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { user } = useAuth0();
  const { data: dataUser, error: errorUser } = useQuery(QUERY_GET_USER_AUTH_INFO, {
    variables: { userAuthId: user?.sub || '' },
    skip: !user?.sub
  });

  const stayOnCustomerPage = dataUser && (head(dataUser.users)!.isSuperUser || !head(dataUser.users)!.company.customer);
  const redirectToCustomerDetails = dataUser && !stayOnCustomerPage;

  if (errorUser) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={errorUser.message}
      />
    );
  }

  if (stayOnCustomerPage) {
    return <CustomersPageContent />;
  }

  if (redirectToCustomerDetails) {
    return (
      <Navigate
        to={`${appConfig.basePath}/customers/${head(dataUser.users)!.companyId}?timezone=${userTimezone}`}
        replace={true}
      />
    );
  }

  return <LoadingPage />;
};

import { Pagination, PaginationProps } from '@mui/material';
import classNames from 'classnames';
import { JSX } from 'react';

export interface RSPaginationProps extends PaginationProps {
  extraClassNames?: string[];
}

/**
 * This component renders a Pagination component. It is based on the MUI Pagination component.
 * The API is the same as the MUI Pagination component: https://mui.com/material-ui/api/pagination/
 * Only commonly used properties are listed below.
 *
 * @param {string[]} [extraClassNames] Extra class names for RSPagination.
 * @param {number} [count] The number of pages.
 * @param {number} [page] The current page number.
 * @returns {React.JSX.Element} A React element that renders a RSPagination component.
 */
export const RSPagination = ({ extraClassNames, ...props }: RSPaginationProps): JSX.Element => {
  const paginationClassNames = classNames('rs-pagination', extraClassNames || []);
  return (
    <Pagination
      {...props}
      shape="rounded"
      variant="outlined"
      className={paginationClassNames}
      data-testid="rs-pagination"
    />
  );
};

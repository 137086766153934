import { includes, isNil } from 'lodash';
import { z } from 'zod';

import i18n from '../../../../i18n';
import { browserSupportedTimezones } from '../../../../utilities';

export const timezoneSchema = z.object({
  timezone: z
    .string()
    .nullish()
    .refine(
      (value) => {
        if (!isNil(value) && !includes(browserSupportedTimezones, value)) {
          return false;
        }
        return true;
      },
      {
        message: i18n.t('forms.inputValidation.invalidTimezone'),
        path: ['timezone']
      }
    )
});

export type TimezoneSearchParameters = z.infer<typeof timezoneSchema>;

import { isEmpty, kebabCase } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

interface SiteCardProgramsProps {
  programs?: string[];
}

export const SiteCardPrograms = (props: SiteCardProgramsProps): JSX.Element => {
  const { t } = useTranslation();

  if (isEmpty(props.programs)) {
    return (
      <div className="site-card__programs-value" data-testid="site-card-no-program">
        {t('noData')}
      </div>
    );
  }

  return (
    <div className="site-card__programs-value" data-testid="site-card-programs">
      {props.programs?.map((program) => (
        <p className="site-card__programs-value-program" key={kebabCase(program)}>
          {program}
        </p>
      ))}
    </div>
  );
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnotationStatus, AnnotationStatusInterface } from '../../../../types';

interface OperationResultTooltipTitleProps extends AnnotationStatusInterface {
  annotatedResult?: string;
  originalResult?: string;
}

export const OperationResultTooltipTitle = ({
  annotationStatus,
  annotatedResult,
  originalResult
}: OperationResultTooltipTitleProps): ReactNode => {
  const { t } = useTranslation();

  switch (annotationStatus) {
    case AnnotationStatus.Confirmed:
      return t('dataGrid.operationResult.tooltips.confirmedResult', { result: annotatedResult });
    case AnnotationStatus.Overridden:
      return (
        <div className="data-grid-operation-result-cell__result-tooltip">
          <span>{t('dataGrid.operationResult.tooltips.overriddenResult', { result: annotatedResult })}</span>
          <span>
            {t('dataGrid.operationResult.tooltips.originalResult', {
              result: originalResult || t('operationsPage.results.inProgress')
            })}
          </span>
        </div>
      );
    default:
      return t('dataGrid.operationResult.tooltips.originalResult', {
        result: originalResult || t('operationsPage.results.inProgress')
      });
  }
};

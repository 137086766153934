import { gql } from '../../../__generated__/gql';

export const QUERY_GET_SERIAL_NUMBERS = gql(`
  query getSerialNumbers($filters: DeviceBoolExp) {
    devices(orderBy: { serialNumber: ASC }, where: $filters) {
      id
      serialNumber
    }
  }
`);

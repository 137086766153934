import { useTranslation } from 'react-i18next';

import ImageCircleIcon from '../../../../assets/icons/image-circle.svg?react';
import { Loading } from '../../../5-elements';

interface SiteImpressionPlaceholderProps {
  message?: string;
  isLoading?: boolean;
}

export const SiteImpressionPlaceholder = ({ message, isLoading }: SiteImpressionPlaceholderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="site-impression-placeholder">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="site-impression-placeholder__icon">
            <ImageCircleIcon />
          </div>
          <p className="site-impression-placeholder__text">
            {message || t('deviceDetailsPage.sidePanel.siteImpression.placeholder')}
          </p>
        </>
      )}
    </div>
  );
};

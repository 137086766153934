import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnotationStatus, AnnotationStatusInterface } from '../../../../types';
import { OperationAnnotationIcon } from '../operation-annotation-icon';

export const OperationAnnotationStatus = (props: AnnotationStatusInterface): JSX.Element => {
  const { t } = useTranslation();

  const determineAnnotatedStatus = (annotationStatus: AnnotationStatus): string => {
    switch (annotationStatus) {
      case AnnotationStatus.Confirmed:
        return t('operationAnnotationStatus.confirmed');
      case AnnotationStatus.Overridden:
        return t('operationAnnotationStatus.overridden');
      default:
        return t('operationAnnotationStatus.notAnnotated');
    }
  };

  return (
    <div className="operation-annotation-status">
      <div className="operation-annotation-status__icon-container">
        <OperationAnnotationIcon {...props} />
      </div>
      <span className="operation-annotation-status__status">{determineAnnotatedStatus(props.annotationStatus)}</span>
    </div>
  );
};

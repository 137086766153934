import { isBoolean } from 'lodash';

import { GetDeviceDetailsQuery, GetOperationDetailsQuery, GetOperationsQuery } from '../../__generated__/graphql';
import i18n from '../../i18n';
import { Flatten } from '../../types';

type DeviceOperation =
  | Flatten<GetOperationsQuery['deviceOperations']>
  | Flatten<NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceOperations']>
  | NonNullable<GetOperationDetailsQuery['deviceOperationByPK']>;

export const generateOperationResultLabel = (
  deviceOperation: Pick<DeviceOperation, 'success' | 'deviceOperationFinalResult'>
): string => {
  if (!isBoolean(deviceOperation.success)) {
    return i18n.t('operationsPage.operationDetails.fallbackOperationResults.inProgress');
  }
  if (deviceOperation.deviceOperationFinalResult) {
    return deviceOperation.deviceOperationFinalResult.name;
  }
  // Some operation do have success true/false but no final result
  if (deviceOperation.success) {
    return i18n.t('operationsPage.operationDetails.fallbackOperationResults.success');
  }
  return i18n.t('operationsPage.operationDetails.fallbackOperationResults.failed');
};

import { ShowMore } from '@re-dev/react-truncate';
import { JSX, MouseEventHandler } from 'react';

import { RSActionLink } from '../rs-action-link';

interface LongTextProps {
  text: string;
  actionLinkText: string;
  handleActionLinkClick: MouseEventHandler<HTMLButtonElement>;
}

export const LongText = ({ text, actionLinkText, handleActionLinkClick }: LongTextProps): JSX.Element => {
  return (
    <div className="long-text" data-testid="long-text">
      <ShowMore
        lines={1}
        more={<RSActionLink handleClick={handleActionLinkClick}>{actionLinkText}</RSActionLink>}
        data-testid="long-text-show-more"
      >
        {text}
      </ShowMore>
    </div>
  );
};

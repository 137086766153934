import { GetDeviceDetailsQuery } from '../__generated__/graphql';

export enum DeviceState {
  Operational = 'Operational',
  Maintenance = 'Maintenance',
  Recoverable = 'Recoverable',
  Irrecoverable = 'Irrecoverable',
  Unknown = 'Unknown'
}

export type DeviceStatusIconVariant =
  | 'normal'
  | 'critical'
  | 'improvement-possible' // Not used at this moment
  | 'attention-required'
  | 'maintenance-required'
  | 'no-monitoring-active'
  | 'offline';

export type DeviceMeasurementValues = NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceMeasurementValues'];

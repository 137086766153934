import { JSX, PropsWithChildren } from 'react';

import { PortalHeader, PortalMain } from '../../3-sections';
import { MainNavMenu } from '../../4-features';
import { PortalGrid } from '../portal-grid';

type HeaderNavigationWrapperProps = PropsWithChildren;

export const HeaderNavigationWrapper = (props: HeaderNavigationWrapperProps): JSX.Element => {
  return (
    <PortalGrid>
      <PortalHeader />
      <PortalMain>
        <MainNavMenu />
        {props.children}
      </PortalMain>
    </PortalGrid>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { JSX, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserMenuAvatar } from './user-menu-avatar';
import LogoutIcon from '../../../assets/icons/log-out.svg?react';
import { appConfig } from '../../../configs';
import { RSTooltip } from '../../5-elements';

interface UserMenuProps {
  imageUrl?: string;
  userName?: string;
  email?: string;
}

export const UserMenu = (props: UserMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { baseUrl } = appConfig;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorElement(null);
  };

  return (
    <>
      <RSTooltip title={props.userName || ''}>
        <IconButton
          onClick={handleClick}
          data-testid="user-menu-icon-button"
          className="user-menu__icon-button"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <UserMenuAvatar imageUrl={props.imageUrl} />
          <div className="user-menu__name-buglog" data-buglog="userName">
            {props.userName || ''}
            {props.email ? `- ${props.email}` : ''}
          </div>
        </IconButton>
      </RSTooltip>
      <Menu
        className="user-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{ className: 'user-menu__list' }}
        PopoverClasses={{
          paper: 'user-menu__popover'
        }}
      >
        <div className="user-menu__user">
          <UserMenuAvatar imageUrl={props.imageUrl} />
          <p className="user-menu__name" data-testid="user-menu-name">
            {props.userName || ''}
          </p>
        </div>
        <Divider className="user-menu__divider" />
        <MenuItem
          className="user-menu__logout"
          data-testid="user-menu-logout"
          onClick={() => logout({ logoutParams: { returnTo: `${baseUrl}/portal` } })}
        >
          <ListItemIcon className="user-menu__logout-icon">
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText className="user-menu__logout-text">
            <p>{t('userMenu.logout')}</p>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

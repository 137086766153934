import { GridColDef } from '@mui/x-data-grid';

import { CustomerRow } from './generate-customer-rows';
import { appConfig } from '../../../../configs';
import i18n from '../../../../i18n';
import { ACDCycleOperationResultSuccessRates } from '../../../../types';
import { DataGridHeaderCell, DataGridLink, DataGridPerformanceMetricCell } from '../../../5-elements';

export const customersDataGridColumns: GridColDef<CustomerRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 250,
    headerName: i18n.t('customersPage.tableColumns.name'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    },
    renderCell: ({ id, value }) => {
      return <DataGridLink to={`${appConfig.basePath}/customers/${id}`}>{value}</DataGridLink>;
    }
  },
  {
    field: 'operationalPerformance',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 216,
    headerName: i18n.t('customersPage.tableColumns.operationalPerformance'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    },
    renderCell: ({ value }) => {
      const operationalPerformanceValue = value as ACDCycleOperationResultSuccessRates;
      return (
        <DataGridPerformanceMetricCell
          metric={{
            value: operationalPerformanceValue.acdCycleOperationsSuccessRate,
            displayUnit: i18n.t('units.percentage')
          }}
          metricChange={{
            value: operationalPerformanceValue.acdCycleOperationsSuccessRateChange,
            displayUnit: i18n.t('units.percentage')
          }}
          metricCount={{
            value: operationalPerformanceValue.acdCycleOperationsCount,
            displayUnit: i18n.t('dataGrid.operationalPerformance.units.metricCount')
          }}
        />
      );
    }
  },
  {
    field: 'devicesCount',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 100,
    headerName: i18n.t('customersPage.tableColumns.devicesCount'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  },
  {
    field: 'sitesCount',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 100,
    headerName: i18n.t('customersPage.tableColumns.sitesCount'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  },
  {
    field: 'programsCount',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 100,
    headerName: i18n.t('customersPage.tableColumns.programsCount'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  }
];

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { JSX, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth0Config } from '../../configs';

type Auth0ProviderWithNavigateProps = PropsWithChildren;

export const Auth0ProviderWithNavigate = ({ children }: Auth0ProviderWithNavigateProps): JSX.Element | null => {
  const navigate = useNavigate();
  const { domain, clientId, audience, redirectUri } = auth0Config;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId as string}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience as string
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

import { gql } from '../../../__generated__/gql';

export const MUTATION_COLLECT_DEVICE_METRICS = gql(`
  mutation collectDeviceMetrics($deviceIds: [String!]) {
    collectDeviceMetrics(deviceIds: $deviceIds) {
      device {
        id
        deviceMeasurementValues(
          where: {
            deviceMeasurementProperty: {
              name: {
                _in: [
                  "is_online"
                  "memory_usage"
                  "memory_total"
                  "storage_usage"
                  "storage_total"
                  "safety_triggered"
                  "remote_queue_length"
                ]
              }
            }
            endAt: { _isNull: true }
          }
        ) {
          id
          value
          updatedAt
          status
          deviceMeasurementProperty {
            id
            name
            unit
          }
        }
      }
    }
  }
`);

import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { RSButton } from '../../5-elements';

interface DrawerButtonsGroupProps {
  handleCancel?: MouseEventHandler<HTMLButtonElement>;
  handleSave?: MouseEventHandler<HTMLButtonElement>;
  isSaveDisabled?: boolean;
  isCancelDisabled?: boolean;
}

export const DrawerButtonsGroup = (props: DrawerButtonsGroupProps) => {
  const { t } = useTranslation();

  return (
    <div className="drawer-buttons-group" data-testid="drawer-buttons-group">
      <RSButton
        data-testid="drawer-buttons-group-cancel-button"
        onClick={props.handleCancel}
        disabled={props.isCancelDisabled}
      >
        {t('drawerEditButtonsGroup.cancel')}
      </RSButton>
      <RSButton
        data-testid="drawer-buttons-group-save-button"
        variant="contained"
        disabled={props.isSaveDisabled}
        onClick={props.handleSave}
      >
        {t('drawerEditButtonsGroup.save')}
      </RSButton>
    </div>
  );
};

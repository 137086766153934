import i18n from '../../i18n';
import { OperationPeriod } from '../../types';

export const mapOperationPeriodDisplayLabel = (value: OperationPeriod): string | undefined => {
  switch (value) {
    case OperationPeriod.Last90Days:
      return i18n.t('operationsPage.period.last90Days');
    case OperationPeriod.Last30Days:
      return i18n.t('operationsPage.period.last30Days');
    case OperationPeriod.Last7Days:
      return i18n.t('operationsPage.period.last7Days');
    case OperationPeriod.FromStart:
      return i18n.t('operationsPage.period.fromStart');
    case OperationPeriod.CustomRange:
      return i18n.t('operationsPage.period.customRange');
    default:
      return undefined;
  }
};

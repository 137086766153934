import { gql } from '../../../__generated__/gql';

export const MUTATION_UPSERT_DEVICE_IMAGE = gql(`
  mutation upsertDeviceImage(
    $id: String!
    $deviceId: String!
    $fileName: String!
    $order: Int
    $generateThumbnail: Boolean
  ) {
    upsertDeviceImage(
      id: $id
      deviceId: $deviceId
      fileName: $fileName
      order: $order
      generateThumbnail: $generateThumbnail
    ) {
      id
      deviceImage {
        id
        device {
          id
          deviceImages {
            id
          }
        }
      }
    }
  }
`);

import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorPage } from '../error-page';

export const PageNotFoundErrorPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      titleEmphasized={t('pageNotFoundErrorPage.errorCode')}
      title={t('pageNotFoundErrorPage.errorTitle')}
      message={t('pageNotFoundErrorPage.notFoundInfo')}
    />
  );
};

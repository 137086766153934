import { CircularProgress } from '@mui/material';
import { JSX, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import BlobUploadIcon from '../../../../assets/icons/blob-upload.svg?react';
import { ObjectUploadStatus, OnlineStatus } from '../../../../types';
import { ObjectUploadStatusMessage, mapObjectUploadStatusDescription } from '../../../../utilities';
import { RSButton } from '../../../5-elements';

interface OperationLogFetchSkippedProps {
  /* Whether there is an error when requesting the log URL */
  hasErrorLogUrl: boolean;
  /* The device online status */
  deviceOnlineStatus: OnlineStatus;
  /* The log upload status */
  logStatus: ObjectUploadStatus;
  /* Whether the portal is requesting the log */
  requestingLogStatus: boolean;
  /* The method to handle log request from the remote server */
  handleRequestLog: MouseEventHandler<HTMLButtonElement>;
}

export const OperationLogFetchSkipped = ({
  hasErrorLogUrl,
  deviceOnlineStatus,
  logStatus,
  requestingLogStatus,
  handleRequestLog
}: OperationLogFetchSkippedProps): JSX.Element => {
  const { t } = useTranslation();
  const generateLogFetchStatusMessage = (
    hasErrorLogUrl: boolean,
    logStatus: ObjectUploadStatus,
    deviceOnlineStatus: OnlineStatus
  ): ObjectUploadStatusMessage => {
    if (hasErrorLogUrl) {
      return {
        title: t(`operationsPage.operationDetails.logModal.titleNotCollected`),
        main: t('operationsPage.operationDetails.logModal.urlRequestError'),
        detail: null
      };
    }
    return mapObjectUploadStatusDescription(logStatus, deviceOnlineStatus, 'log');
  };

  const uploadStatusMessages = generateLogFetchStatusMessage(hasErrorLogUrl, logStatus, deviceOnlineStatus);
  return (
    <div className="operation-log-fetch-skipped" data-testid="operation-log-fetch-skipped">
      <div className="operation-log-fetch-skipped__icon-container">
        <BlobUploadIcon />
      </div>
      <h3 className="operation-log-fetch-skipped__title">{uploadStatusMessages.title}</h3>
      <span className="operation-log-fetch-skipped__status" data-testid="operation-log-fetch-skipped-status">
        {uploadStatusMessages.main}
      </span>
      <span
        className="operation-log-fetch-skipped__status-detail"
        data-testid="operation-log-fetch-skipped-status-detail"
      >
        {uploadStatusMessages.detail}
      </span>
      <RSButton
        variant="contained"
        disabled={
          requestingLogStatus ||
          logStatus === ObjectUploadStatus.UploadFailed ||
          logStatus === ObjectUploadStatus.UploadFailedNotFound
        }
        onClick={handleRequestLog}
        data-testid="operation-log-request-button"
      >
        {requestingLogStatus ? (
          <span className="operation-log-fetch-skipped__button-requesting">
            <CircularProgress size={16} />
            {t('operationsPage.operationDetails.logModal.requesting')}
          </span>
        ) : (
          t('operationsPage.operationDetails.logModal.requestLog')
        )}
      </RSButton>
    </div>
  );
};

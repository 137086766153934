import { QueryHookOptions, useQuery } from '@apollo/client';

import { DeviceOperationBoolExp, GetAllDeviceOperationsPerformanceQuery } from '../../../../__generated__/graphql';
import { QUERY_GET_ALL_DEVICE_OPERATIONS_PERFORMANCE } from '../../../../services/queries';
import { calculateSuccessPercentage, execIfBothDefined } from '../../../../utilities';
import { PerformanceMetricsRender } from '../../../4-features';

interface OperationPerformanceMetricsProps {
  filters: DeviceOperationBoolExp;
  queryHookOptions?: QueryHookOptions<GetAllDeviceOperationsPerformanceQuery>;
}

export const OperationPerformanceMetrics = ({ filters, queryHookOptions }: OperationPerformanceMetricsProps) => {
  const { loading, data, error } = useQuery(QUERY_GET_ALL_DEVICE_OPERATIONS_PERFORMANCE, {
    ...queryHookOptions,
    variables: {
      filtersSuccess: { ...filters, deviceOperationFinalResult: { isSuccessful: { _eq: true } } },
      filtersFailed: { ...filters, deviceOperationFinalResult: { isSuccessful: { _eq: false } } }
    }
  });
  const acdCycleOperationsSuccess = data?.totalACDCycleOperationsSuccess.aggregate?.count ?? 0;
  const acdCycleOperationsFailed = data?.totalACDCycleOperationsFailed.aggregate?.count ?? 0;
  const acdCycleOperationsSuccessRate = calculateSuccessPercentage(acdCycleOperationsFailed, acdCycleOperationsSuccess);
  const acdCycleOperationsCount = execIfBothDefined(
    acdCycleOperationsSuccess,
    acdCycleOperationsFailed,
    (val1, val2) => val1 + val2
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsSuccessRate={acdCycleOperationsSuccessRate}
      acdCycleOperationsCount={acdCycleOperationsCount}
      loadingTotalAcdCyclesOperationResult={loading}
      errorTotalAcdCyclesOperationResult={!!error}
      isRawPerformance={true}
    />
  );
};

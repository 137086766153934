import { GridRowId } from '@mui/x-data-grid';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '../../../../configs';
import { mapDeviceStateToStatusIcon } from '../../../../utilities';
import { DeviceStatusIcon } from '../../../4-features';
import { DataGridLink, RSTooltip } from '../../../5-elements';
import { DeviceRowDevice } from '../data-grid-configurations';

type DeviceStateSerialNumberProps = DeviceRowDevice['serialNumber'] & {
  id: GridRowId;
};

export const DeviceStateSerialNumber = (props: DeviceStateSerialNumberProps): JSX.Element => {
  const { t } = useTranslation();
  const isDeviceOffline = props.deviceOnlineStatus?.value === 0;
  const statusIconVariant = mapDeviceStateToStatusIcon(props.deviceLastState?.deviceStatus, isDeviceOffline);

  return (
    <div className="device-state-serial-number" data-testid="device-state-serial-number">
      <RSTooltip
        title={
          <div className="device-state-serial-number__indicator" data-testid="device-state-serial-number-tooltip">
            <div className="device-state-serial-number__indicator-device-state">
              <span className="device-state-serial-number__indicator-title">
                {t('devicesPage.deviceStateSerialNumber.deviceState')}
              </span>
              <span>
                {props.deviceLastState?.deviceStatus}
                {isDeviceOffline && t('devicesPage.deviceStateSerialNumber.lastKnown')}
              </span>
            </div>
            {isDeviceOffline && (
              <div className="device-state-serial-number__indicator-connectivity">
                <span className="device-state-serial-number__indicator-title">
                  {t('devicesPage.deviceStateSerialNumber.connectivity')}
                </span>
                <span>{t('devicesPage.deviceStateSerialNumber.offline')}</span>
              </div>
            )}
          </div>
        }
        className="device-state-serial-number__tooltip"
      >
        {/* Without this <div> the tooltip will not render. WHY? */}
        <div data-testid="device-state-serial-number-icon-hover-zone">
          <DeviceStatusIcon
            statusIconVariant={statusIconVariant}
            extraClassNames="device-state-serial-number__status-icon-container"
          />
        </div>
      </RSTooltip>
      <DataGridLink to={`${appConfig.basePath}/devices/${props.id}`}>{props.serialNumber}</DataGridLink>
    </div>
  );
};

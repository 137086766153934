import { z } from 'zod';

import { OperationalLifeCycle } from '../../../../../__generated__/graphql';

export const deviceOperationalLifeCycleRemarksEditSchema = z.object({
  id: z.string().uuid(),
  operationalLifeCycle: z.nativeEnum(OperationalLifeCycle),
  remarks: z.string().nullish()
});

export type DeviceOperationalLifeCycleRemarksEditRequest = z.infer<typeof deviceOperationalLifeCycleRemarksEditSchema>;

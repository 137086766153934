import { gql } from '../../../__generated__/gql';

export const MUTATION_MODIFY_DEVICE = gql(`
  mutation modifyDevice(
    $id: uuid!
    $remarks: String
    $operationalLifeCycle: OperationalLifeCycle
    $description: String
    $connectorHolderTypeId: uuid
    $deviceTypeId: uuid
    $location: LocationInput
  ) {
    modifyDevice(
      id: $id
      remarks: $remarks
      operationalLifeCycle: $operationalLifeCycle
      description: $description
      connectorHolderTypeId: $connectorHolderTypeId
      deviceTypeId: $deviceTypeId
      location: $location
    ) {
      id
      device {
        id
        remarks
        description
        location {
          id
          latitude
          longitude
        }
        deviceType {
          id
          name
        }
        connectorHolderType {
          id
          code
          connectorType
          name
        }
        operationalLifeCycle
      }
    }
  }
`);

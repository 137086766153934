import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { t } from 'i18next';
import { JSX, useEffect, useState } from 'react';

import { auth0Config } from '../../../configs';
import { ErrorPage, LoadingPage } from '../../1-pages';
import { HeaderOnlyWrapper } from '../../2-templates';

interface LoginRedirectProps {
  redirectUri?: string;
}

export const LoginRedirect = ({ redirectUri }: LoginRedirectProps): JSX.Element => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [auth0Error, setAuth0Error] = useState<OAuthError | undefined>(undefined);

  const loginToAuth0 = async (): Promise<void> => {
    try {
      await getAccessTokenSilently({
        authorizationParams: {
          audience: auth0Config.audience as string
        }
      });
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified. ts(1196)
    } catch (error: unknown) {
      // await getAccessTokenSilently() throws OAuthError
      const typedError = error as OAuthError;
      switch (typedError.error) {
        case 'login_required':
        case 'consent_required':
        case 'missing_refresh_token':
          loginWithRedirect({
            appState: {
              returnTo: redirectUri || 'portal'
            }
          });
          break;
        default:
          setAuth0Error(typedError);
      }
    }
  };

  useEffect(() => {
    loginToAuth0();
  }, [getAccessTokenSilently]);

  // With <PageWrapper />, the header will only be shown if the user has been successfully authenticated, as the header
  // is a child of the <AuthenticatedOnlyWrapper>.
  // When loading or error occurs, the child (which includes the header) will not get rendered. Therefore the header
  // wrapper needs to be used explicitly.
  if (auth0Error) {
    return (
      <HeaderOnlyWrapper>
        <ErrorPage
          title={t('errorPage.errorOccurred')}
          titleEmphasized={t('errorPage.error')}
          message={auth0Error.message}
        />
      </HeaderOnlyWrapper>
    );
  } else {
    return (
      <HeaderOnlyWrapper>
        <LoadingPage />
      </HeaderOnlyWrapper>
    );
  }
};

import { toString } from 'lodash';

import { RSAutocompleteValue } from '../../../../types';

interface RSAutocompleteDefaultMenuOptionProps {
  option: RSAutocompleteValue;
}

export const RSAutocompleteDefaultMenuOption = ({ option }: RSAutocompleteDefaultMenuOptionProps) => {
  return (
    <span
      className="rs-autocomplete__option"
      title={toString(option)}
      data-testid={`rs-autocomplete-option-value-${option}`}
    >
      {option}
    </span>
  );
};

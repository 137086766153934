import { useTranslation } from 'react-i18next';

import PencilIcon from '../../../assets/icons/pencil.svg?react';
import { RSActionLink, RSActionLinkProps } from '../../5-elements';

type EditButtonProps = RSActionLinkProps;

export const EditButton = (props: EditButtonProps) => {
  const { t } = useTranslation();

  return (
    <RSActionLink {...props} data-testid={props['data-testid'] || 'edit-button'} extraClassNames={['edit-button']}>
      <span className="edit-button__icon">
        <PencilIcon />
      </span>
      <span className="edit-button__text">{t('editButton.edit')}</span>
    </RSActionLink>
  );
};

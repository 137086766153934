import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_WITH_GROUP_INFO = gql(`
  query getDeviceWithGroupInfo($deviceId: uuid!) {
    deviceByPK(id: $deviceId) {
      id
      site {
        id
        customerId
        customer {
          companyId
          allowImport
        }
      }
      deviceGroupDevices {
        deviceGroup {
          id
          customerId
          serviceProviderId
        }
      }
    }
  }
`);

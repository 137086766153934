import { gql } from '../../../__generated__/gql';

export const QUERY_GET_OPERATIONS = gql(`
  query getOperations(
    $offset: Int
    $limit: Int
    $filters: DeviceOperationBoolExp
    $orderBy: [DeviceOperationOrderBy!]
  ) {
    deviceOperationsAggregate(where: $filters) {
      aggregate {
        count
      }
    }
    deviceOperations(orderBy: $orderBy, offset: $offset, limit: $limit, where: $filters) {
      id
      sequence
      startAt
      endAt
      operationalLifeCycle
      success
      errorDetails
      isAnnotated
      deviceOperationResult {
        id
        isSuccessful
        name
        code
        updatedAt
      }
      deviceOperationAnnotatedResult {
        id
        isSuccessful
        name
        code
        updatedAt
      }
      deviceOperationFinalResult {
        id
        isSuccessful
        name
        code
        updatedAt
      }
      deviceOperationAnnotationAudits(orderBy: { annotatedAt: DESC }) {
        id
        annotatedAt
      }
      device {
        id
        serialNumber
        site {
          id
          name
          customer {
            companyId
            company {
              id
              name
            }
          }
        }
        program {
          id
          name
        }
      }
    }
  }
`);

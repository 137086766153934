import { Drawer, DrawerProps } from '@mui/material';
import classNames from 'classnames';
import { JSX } from 'react';

export type RSDrawerProps = DrawerProps;

/**
 * This component renders a RSDrawer component. It is based on the MUI Chip component.
 * The API is the same as the MUI Chip component: https://mui.com/material-ui/api/chip/
 * Only commonly used properties are listed below.
 *
 * @param {React.ReactNode} [children] The contents of the drawer.
 * @param {ModalProps['onClose']} [onClose] The function to call when the drawer is closed.
 * @returns {React.JSX.Element} A React element that renders a RSDrawer component.
 */

export const RSDrawer = ({ children, ...props }: RSDrawerProps): JSX.Element => {
  return (
    <Drawer {...props} className={classNames('rs-drawer', props.className)} anchor="right" disableEnforceFocus>
      {children}
    </Drawer>
  );
};

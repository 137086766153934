import { SiteCardLocationLink } from './site-card-location-link';
import LocationIcon from '../../../assets/icons/location.svg?react';
import { RSCardDefaultTitle } from '../../5-elements';

interface SiteCardTitleProps {
  name: string;
  address: string | undefined | null;
  latitude: number;
  longitude: number;
}

export const SiteCardTitle = (props: SiteCardTitleProps): JSX.Element => {
  return (
    <div className="site-card-title" data-testid="site-card-title">
      <RSCardDefaultTitle title={props.name} />
      <div className="site-card-title__location" data-testid="site-card-title-location">
        <LocationIcon className="site-card-title__location-icon" />
        <SiteCardLocationLink address={props.address} latitude={props.latitude} longitude={props.longitude} />
      </div>
    </div>
  );
};

import { DeviceOperationBoolExp } from '../../../../__generated__/graphql';

/*
  Here the `customerId` is fetched through the URL match from react-router-dom. Although the type can be `undefined` in
  theory, in reality the user cannot reach the CustomerDetailsPage if `customerId` is undefined. This method will only
  be called if the user has successfully reached the CustomerDetailsPage, therefore in practice `customerId` shall not
  be `undefined`. If the user managed to puts `undefined` to generate the query, let it fail (hasError = true for the
  metrics component).
*/
export const generateCustomerDetailsPerformanceMetricsFilter = (customerId: string): DeviceOperationBoolExp => {
  return { device: { site: { customerId: { _eq: customerId } } } };
};

import { includes, uniq } from 'lodash';
import { DateTime } from 'luxon';

import { DeviceOperationBoolExp } from '../../../../__generated__/graphql';
import { DEFAULT_GET_OPERATIONS_FILTER } from '../../../../constants';
import { AnnotationResult, OperationPeriod } from '../../../../types';
import { calculateTimeRangeFromPeriod, mapDeviceDeactivated } from '../../../../utilities';
import { OperationsOverviewSearchParameters } from '../operations-overview-states-schema';

export const operationsFilterFields = [
  'serialNumber',
  'result',
  'annotation',
  'period',
  'dateTime',
  'operationalLifeCycle',
  'customer',
  'site',
  'program',
  'deactivated'
] as const;

type OperationsFilterField = (typeof operationsFilterFields)[number];

export const IN_PROGRESS_CODE = 'IN_PROGRESS';

export const generateFilterQuery = (
  filterParameters: Pick<OperationsOverviewSearchParameters, OperationsFilterField>,
  timezone: string
): DeviceOperationBoolExp => {
  const filterQuery: DeviceOperationBoolExp = { _and: [DEFAULT_GET_OPERATIONS_FILTER] };
  if (filterParameters.serialNumber) {
    filterQuery._and!.push({ device: { serialNumber: { _in: uniq(filterParameters.serialNumber) } } });
  }
  if (filterParameters.result) {
    if (!includes(filterParameters.result, IN_PROGRESS_CODE)) {
      filterQuery._and!.push({ deviceOperationFinalResult: { code: { _in: uniq(filterParameters.result) } } });
      filterQuery._and!.push({ success: { _isNull: false } });
    } else {
      const codeNoInProgress = filterParameters.result.filter((code) => code !== IN_PROGRESS_CODE);
      filterQuery._or = [
        { success: { _isNull: true } },
        { deviceOperationFinalResult: { code: { _in: uniq(codeNoInProgress) } } }
      ];
    }
  }
  if (filterParameters.period) {
    if (
      filterParameters.period === OperationPeriod.Last7Days ||
      filterParameters.period === OperationPeriod.Last30Days ||
      filterParameters.period === OperationPeriod.Last90Days
    ) {
      const timeRange = calculateTimeRangeFromPeriod(filterParameters.period, timezone);
      filterQuery._and!.push({
        startAt: { _gte: timeRange[0]!.toISO(), _lt: timeRange[1]!.toISO() }
      });
    }
    if (filterParameters.period === OperationPeriod.CustomRange) {
      if (!filterParameters.dateTime) {
        const timeRange90Days = calculateTimeRangeFromPeriod(OperationPeriod.Last90Days, timezone);
        filterQuery._and!.push({
          startAt: { _gte: timeRange90Days[0]!.toISO(), _lt: timeRange90Days[1]!.toISO() }
        });
      } else {
        const startAtFrom = DateTime.fromISO(filterParameters.dateTime[0]).toISO();
        const startAtTo = DateTime.fromISO(filterParameters.dateTime[1]).toISO();
        filterQuery._and!.push({ startAt: { _gte: startAtFrom, _lt: startAtTo } });
      }
    }
  }
  if (filterParameters.operationalLifeCycle) {
    filterQuery._and!.push({ operationalLifeCycle: { _in: uniq(filterParameters.operationalLifeCycle) } });
  }
  if (filterParameters.annotation) {
    if (filterParameters.annotation.length === 1) {
      filterQuery._and!.push({
        deviceOperationAnnotatedResultId: {
          // if annotated: isNull = false; if not annotated: isNull = true
          _isNull: filterParameters.annotation[0] === AnnotationResult.NotAnnotated
        }
      });
    }
  }
  if (filterParameters.deactivated) {
    const uniqueActiveFilterValue = uniq(filterParameters.deactivated);
    if (uniqueActiveFilterValue.length === 1) {
      filterQuery._and!.push({ device: { deactivated: { _eq: mapDeviceDeactivated(uniqueActiveFilterValue[0]) } } });
    }
  }
  if (filterParameters.customer) {
    filterQuery._and!.push({
      device: { site: { customer: { company: { name: { _in: uniq(filterParameters.customer) } } } } }
    });
  }
  if (filterParameters.site) {
    filterQuery._and!.push({ device: { site: { name: { _in: uniq(filterParameters.site) } } } });
  }
  if (filterParameters.program) {
    filterQuery._and!.push({ device: { program: { name: { _in: uniq(filterParameters.program) } } } });
  }

  return filterQuery;
};

import { IconButton, IconButtonProps } from '@mui/material';
import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

import CrossIcon from '../../../assets/icons/cross.svg?react';

export interface ModalDrawerHeaderProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  handleClose?: MouseEventHandler<HTMLButtonElement>;
  actions?: ReactNode;
  iconButtonProps?: IconButtonProps;
}

export const ModalDrawerHeader = (props: ModalDrawerHeaderProps): JSX.Element => {
  return (
    <div
      className={classNames('modal-drawer-header', { 'modal-drawer-header--subtitle': Boolean(props.subtitle) })}
      data-testid="modal-drawer-header"
    >
      <div className="modal-drawer-header__titles">
        {props.title && <h2 className="modal-drawer-header__title">{props.title}</h2>}
        {props.subtitle && props.subtitle}
      </div>
      <div className="modal-drawer-header__button-bar">
        {props.actions && <div className="modal-drawer-header__actions">{props.actions}</div>}
        {props.handleClose && (
          <IconButton
            {...props.iconButtonProps}
            className="modal-drawer-header__close-button"
            onClick={props.handleClose}
            data-testid="modal-drawer-header-close-button"
          >
            <CrossIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

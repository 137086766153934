import { GetDeviceOperationsPerformanceQuery } from '../../__generated__/graphql';

export const getAcdCycleOperationsCounts = (
  dataTotalACDCyclesOperationResult: GetDeviceOperationsPerformanceQuery | undefined
) => {
  const acdCycleOperationsSuccess =
    dataTotalACDCyclesOperationResult?.totalACDCycleOperationsSuccess.aggregate?.count ?? 0;

  const acdCycleOperationsFailed =
    dataTotalACDCyclesOperationResult?.totalACDCycleOperationsFailed.aggregate?.count ?? 0;

  const acdCycleOperationsPrevSuccess =
    dataTotalACDCyclesOperationResult?.totalACDCycleOperationsPrevSuccess.aggregate?.count ?? 0;

  const acdCycleOperationsPrevFailed =
    dataTotalACDCyclesOperationResult?.totalACDCycleOperationsPrevFailed.aggregate?.count ?? 0;

  return {
    acdCycleOperationsSuccess,
    acdCycleOperationsFailed,
    acdCycleOperationsPrevSuccess,
    acdCycleOperationsPrevFailed
  };
};

import { EnvironmentSetting } from '../../types/environment-setting';
import { HostNameSuffix } from '../../types/host-name-suffix';

export const getEnvironmentValue = (setting: EnvironmentSetting, hostName: string) => {
  if (hostName.includes(HostNameSuffix.Int)) {
    return setting.INT;
  }
  if (hostName.includes(HostNameSuffix.Acc)) {
    return setting.ACC;
  }
  if (hostName.includes(HostNameSuffix.Prod)) {
    return setting.PROD;
  }
  return setting.DEV;
};

import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { GridEventListener, GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { get, head, isEqual, pick, toNumber, toString } from 'lodash';
import { DateTime } from 'luxon';
import qs from 'qs';
import { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { OperationRow, generateOperationRows, operationsDataGridColumns } from './data-grid-configurations';
import { generateFilterQuery, generateSortQuery, operationsFilterFields } from './generate-queries';
import { OperationPerformanceMetrics } from './operation-performance-metrics';
import { OperationsFilterPanel } from './operations-filter-panel';
import {
  OperationsOverviewSearchParameters,
  operationsOverviewStatesSchema
} from './operations-overview-states-schema';
import { DeviceOperationBoolExp, DeviceOperationOrderBy } from '../../../__generated__/graphql';
import { DEFAULT_GET_OPERATIONS_FILTER, DEFAULT_GET_OPERATIONS_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants';
import {
  QUERY_GET_CUSTOMER_NAMES,
  QUERY_GET_OPERATIONS,
  QUERY_GET_OPERATION_RESULTS,
  QUERY_GET_PROGRAM_NAMES,
  QUERY_GET_SERIAL_NUMBERS,
  QUERY_GET_SITE_NAMES,
  QUERY_GET_USER_AUTH_INFO
} from '../../../services/queries';
import { DeviceDeactivated, OperationPeriod } from '../../../types';
import {
  browserTimezone,
  calculatePaginationEndRow,
  calculatePaginationPageCount,
  calculatePaginationStartRow,
  calculateTimeRangeFromPeriod,
  filterValidUrlFields
} from '../../../utilities';
import { OverviewMainSectionWrapper } from '../../2-templates';
import { OperationDetails } from '../../3-sections';
import { PaginationBar, RSDataGrid } from '../../4-features';
import { Loading, ScreenTitle } from '../../5-elements';
import { UserTimezoneContext } from '../../contexts';
import { ErrorPage } from '../error-page';
import { LoadingPage } from '../loading-page';

export const OperationsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { user } = useAuth0();
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortingOptions, setSortingOptions] = useState<DeviceOperationOrderBy[]>(DEFAULT_GET_OPERATIONS_SORT_BY);
  const [filterOptions, setFilterOptions] = useState<DeviceOperationBoolExp>({ _and: [DEFAULT_GET_OPERATIONS_FILTER] });
  const [queryParamsLoaded, setQueryParamsLoaded] = useState<boolean>(false);
  const [selectedOperationId, setSelectedOperationId] = useState<string | undefined>(undefined);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(QUERY_GET_USER_AUTH_INFO, {
    variables: { userAuthId: user?.sub || '' },
    skip: !user?.sub
  });
  const { loading, data, error } = useQuery(QUERY_GET_OPERATIONS, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: sortingOptions,
      filters: filterOptions
    },
    fetchPolicy: 'cache-and-network',
    skip: !queryParamsLoaded
  });
  const {
    loading: loadingSerialNumbers,
    data: dataSerialNumbers,
    error: errorSerialNumbers
  } = useQuery(QUERY_GET_SERIAL_NUMBERS, { fetchPolicy: 'network-only' });
  const {
    loading: loadingCustomerNames,
    data: dataCustomerNames,
    error: errorCustomerNames
  } = useQuery(QUERY_GET_CUSTOMER_NAMES, {
    fetchPolicy: 'network-only',
    variables: { filters: { sites: { devices: {} } } }
  });
  const {
    loading: loadingSiteNames,
    data: dataSiteNames,
    error: errorSiteNames
  } = useQuery(QUERY_GET_SITE_NAMES, { fetchPolicy: 'network-only', variables: { filters: { devices: {} } } });
  const {
    loading: loadingOperationResults,
    data: dataOperationResults,
    error: errorOperationResults
  } = useQuery(QUERY_GET_OPERATION_RESULTS, { fetchPolicy: 'network-only' });
  const {
    loading: loadingProgramNames,
    data: dataProgramNames,
    error: errorProgramNames
  } = useQuery(QUERY_GET_PROGRAM_NAMES, { fetchPolicy: 'network-only', variables: { filters: { devices: {} } } });

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<OperationRow>) => {
    searchParams.set('operationId', params.row.id);
    setSearchParams(searchParams);
  };

  const handleOperationDetailsClose = (): void => {
    searchParams.delete('operationId');
    setSearchParams(searchParams);
  };

  const searchParameters = qs.parse(searchParams.toString());

  const validUrlFields = filterValidUrlFields<OperationsOverviewSearchParameters>(
    searchParameters,
    operationsOverviewStatesSchema
  );

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }

    // Set sorting
    const sortParameter = get(validUrlFields, 'sort');
    const sortQuery = generateSortQuery(sortParameter);
    if (!isEqual(sortQuery, sortingOptions)) {
      setSortingOptions(sortQuery);
    }

    // Set filtering
    const filterParameters = pick(validUrlFields, operationsFilterFields);
    if (
      !filterParameters.period ||
      (filterParameters.period === OperationPeriod.CustomRange && !filterParameters.dateTime)
    ) {
      searchParams.set('period', OperationPeriod.Last90Days);
      setSearchParams(searchParams, { replace: true });
    }
    if (
      filterParameters.period &&
      filterParameters.period !== OperationPeriod.CustomRange &&
      filterParameters.dateTime
    ) {
      searchParams.delete('dateTime');
      setSearchParams(searchParams, { replace: true });
    }
    if (!filterParameters.deactivated && !queryParamsLoaded) {
      searchParams.set('deactivated', DeviceDeactivated.Activated);
      setSearchParams(searchParams, { replace: true });
    }

    const filterQuery = generateFilterQuery(filterParameters, userTimezone || browserTimezone);
    if (!isEqual(filterQuery, filterOptions)) {
      setFilterOptions(filterQuery);
    }

    // Set operation id
    const urlOperationId = get(validUrlFields, 'operationId');
    if (urlOperationId !== selectedOperationId) {
      setSelectedOperationId(urlOperationId);
    }

    // Only enable the operations query if the query params have been loaded, the period and the deactivated has been set
    if (!queryParamsLoaded && validUrlFields.period && validUrlFields.deactivated) {
      setQueryParamsLoaded(true);
    }

    // `searchParams` is a URLSearchParams whilst `routerLocation.search` is a string - compare string-type dependency
    // aligns with "useEffect dependencies compare the value change"
  }, [routerLocation.search]);

  const groupedError =
    error ||
    errorUser ||
    errorSerialNumbers ||
    errorOperationResults ||
    errorCustomerNames ||
    errorSiteNames ||
    errorProgramNames;

  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const isLoading =
    loading ||
    loadingUser ||
    loadingSerialNumbers ||
    loadingOperationResults ||
    loadingCustomerNames ||
    loadingSiteNames ||
    loadingProgramNames;

  if (!validUrlFields.period) {
    return <LoadingPage />;
  }

  if (loadingUser || !dataUser) {
    return (
      <OverviewMainSectionWrapper filter={<></>}>
        <section className="operations-page" data-testid="operations-page">
          <div className="operations-page__overview-top-bar">
            <ScreenTitle title={t('operationsPage.title')} />
            <OperationPerformanceMetrics filters={filterOptions} queryHookOptions={{ skip: !queryParamsLoaded }} />
          </div>
          <div className="operations-page__data-grid-loading">
            <Loading />
          </div>
          <div className="operations-page__pagination">
            <PaginationBar isLoading={isLoading} startRow={0} endRow={0} pageCount={0} page={paginationModel.page} />
          </div>
          <OperationDetails
            operationId={selectedOperationId}
            open={Boolean(selectedOperationId)}
            handleOperationDetailsClose={handleOperationDetailsClose}
            source="operation-overview"
          />
        </section>
      </OverviewMainSectionWrapper>
    );
  }

  const showCustomer = dataUser && (head(dataUser.users)!.isSuperUser || !head(dataUser.users)!.company.customer);

  return (
    <OverviewMainSectionWrapper
      filter={
        <OperationsFilterPanel
          serialNumbers={dataSerialNumbers}
          customerNames={dataCustomerNames}
          operationResults={dataOperationResults}
          siteNames={dataSiteNames}
          programNames={dataProgramNames}
          defaultValues={{
            serialNumber: validUrlFields.serialNumber,
            result: validUrlFields.result,
            period: validUrlFields.period,
            dateTime:
              validUrlFields.period === OperationPeriod.CustomRange
                ? validUrlFields.dateTime!.map((stringDateTime) => DateTime.fromISO(stringDateTime))
                : calculateTimeRangeFromPeriod(validUrlFields.period, userTimezone || browserTimezone),
            operationalLifeCycle: validUrlFields.operationalLifeCycle,
            site: validUrlFields.site,
            customer: validUrlFields.customer,
            program: validUrlFields.program
          }}
          showCustomer={showCustomer}
        />
      }
    >
      <section className="operations-page" data-testid="operations-page">
        <div className="operations-page__overview-top-bar">
          <ScreenTitle title={t('operationsPage.title')} />
          <OperationPerformanceMetrics filters={filterOptions} queryHookOptions={{ skip: !queryParamsLoaded }} />
        </div>
        <div className="operations-page__data-grid-container">
          <RSDataGrid
            initialState={{ columns: { columnVisibilityModel: { customer: showCustomer } } }}
            columns={operationsDataGridColumns}
            rows={generateOperationRows(data, userTimezone)}
            loading={isLoading}
            onRowClick={handleRowClick}
            data-testid="operations-page-data-grid"
          />
        </div>
        <div className="operations-page__pagination">
          <PaginationBar
            isLoading={isLoading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(
              paginationModel.page,
              DEFAULT_PAGE_SIZE,
              data?.deviceOperationsAggregate.aggregate?.count
            )}
            rowCount={data?.deviceOperationsAggregate.aggregate?.count}
            pageCount={calculatePaginationPageCount(data?.deviceOperationsAggregate.aggregate?.count)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = qs.parse(searchParams.toString());
              setSearchParams(
                qs.stringify({ ...originalSearchParamsObject, page: toString(page) }, { arrayFormat: 'brackets' })
              );
            }}
            page={paginationModel.page}
          />
        </div>
        <OperationDetails
          operationId={selectedOperationId}
          open={Boolean(selectedOperationId)}
          handleOperationDetailsClose={handleOperationDetailsClose}
          source="operation-overview"
        />
      </section>
    </OverviewMainSectionWrapper>
  );
};

export const calculateChangePercentage = (prevValue: number | null, newValue: number | null): number | null => {
  if (prevValue === null || newValue === null) {
    return null;
  }

  // mathematically not correct (you can not divide by zero)
  // but it feels intuitive to return 0 if both values are 0
  if (prevValue === 0 && newValue === 0) {
    return 0;
  }
  const changePercentage = ((newValue - prevValue) / prevValue) * 100;

  return isFinite(changePercentage) ? changePercentage : null;
};

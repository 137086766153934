/*
  Contextualize the given boolean value.
  Example: Given a boolean value `true`, if the `trueContext` is `Enabled` and `falseContext` is `Disabled`,
    return `Enabled`.
*/
export const contextualizeBoolean = (
  trueContext: string,
  falseContext: string,
  value?: boolean | string | number | null
): string => {
  return value ? trueContext : falseContext;
};

import { JSX, PropsWithChildren } from 'react';

type PortalGridProps = PropsWithChildren;

export const PortalGrid = (props: PortalGridProps): JSX.Element => {
  return (
    <div className="portal-grid" data-testid="portal-grid">
      {props.children}
    </div>
  );
};

import { LinearProgress, LinearProgressProps } from '@mui/material';
import classNames from 'classnames';
import { JSX } from 'react';

export interface RSLinearProgressProps extends LinearProgressProps {
  customizedLabel?: string;
  widthVariant?: 'short' | 'long';
  labelTextSize?: 'normal' | 'large';
}

/**
 * This component renders a RSLinearProgress component. It is based on the MUI Linear Progress component.
 * The API is the same as the MUI Linear Progress component: https://mui.com/material-ui/api/linear-progress/
 * Only commonly used properties are listed below.
 *
 * @param {string} [color=primary] The color of the chip. RSChip supports 'primary', 'warning', 'error' and 'success'.
 * @param {number} [value] The value of the progress bar in percentage. Note: `undefined` has the same rendering
 *     effect as `100`, out-of-range value (e.g. `103`) has the same rendering effect as `0`.
 * @param {string} [customizedLabel] The label which replaces the default display value (the percentage).
 * @param {'short' | 'long'} [widthVariant=short] The variant of the width.
 * @param {'normal' | 'large'} [labelTextSize=normal] The variant of label font size. `normal`: 11px, `large`: 14px
 * @returns {React.JSX.Element} A React element that renders a RSLinearProgress component.
 */
export const RSLinearProgress = ({
  customizedLabel,
  widthVariant,
  labelTextSize,
  ...props
}: RSLinearProgressProps): JSX.Element => {
  const displayLabel = customizedLabel || `${props.value}%`;
  const progressRootClassNames = classNames({
    'rs-linear-progress__root': true,
    'rs-linear-progress__root--long': widthVariant === 'long'
  });
  const progressLabelClassNames = classNames({
    'rs-linear-progress__label': true,
    'rs-linear-progress__label--large': labelTextSize === 'large'
  });

  return (
    <span className="rs-linear-progress">
      <LinearProgress {...props} variant="determinate" className={progressRootClassNames} />
      <span className={progressLabelClassNames}>{displayLabel}</span>
    </span>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_CUSTOMER_NAMES = gql(`
  query getCustomerNames($filters: CustomerBoolExp) {
    customers(orderBy: { company: { name: ASC } }, where: $filters) {
      companyId
      company {
        id
        name
      }
    }
  }
`);

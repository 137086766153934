import classNames from 'classnames';
import { JSX, PropsWithChildren } from 'react';

interface PortalMainProps extends PropsWithChildren {
  fullWidth?: boolean;
}

export const PortalMain = (props: PortalMainProps): JSX.Element => {
  const portalMainClassNames = classNames({
    'portal-main': true,
    // full-width: no navigation menu
    'portal-main--full-width': props.fullWidth === true
  });

  return (
    <main className={portalMainClassNames} data-testid="portal-main">
      {props.children}
    </main>
  );
};

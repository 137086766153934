import classNames from 'classnames';
import { JSX } from 'react';

interface WarningStripProps {
  icon?: JSX.Element;
  text: string;
  variant?: 'default' | 'warning';
}

export const WarningStrip = ({ icon, text, variant = 'default' }: WarningStripProps): JSX.Element => {
  const warningStripClassNames = classNames('warning-strip', { 'warning-strip--warning': variant === 'warning' });
  return (
    <div className={warningStripClassNames} data-testid="warning-strip">
      {icon && <div className="warning-strip__icon-container">{icon}</div>}
      <p className="warning-strip__text">{text}</p>
    </div>
  );
};

import { JSX, PropsWithChildren } from 'react';

import { PortalHeader, PortalMain } from '../../3-sections';
import { PortalGrid } from '../portal-grid';

type HeaderOnlyWrapperProps = PropsWithChildren;

export const HeaderOnlyWrapper = (props: HeaderOnlyWrapperProps): JSX.Element => {
  return (
    <PortalGrid>
      <PortalHeader />
      <PortalMain fullWidth={true}>{props.children}</PortalMain>
    </PortalGrid>
  );
};

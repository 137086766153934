import { JSX } from 'react';

import {
  OperationAnnotationIcon,
  OperationResultChip,
  OperationResultChipProps,
  OperationResultTooltipTitle
} from '../..';
import { AnnotationStatusInterface } from '../../../../types';
import { generateOperationTimestampTooltipTitle } from '../../../../utilities';
import { RSTooltip } from '../../../5-elements';
import { useFormatTimezone } from '../../../hooks';

export interface DataGridOperationResultCellProps
  extends Pick<OperationResultChipProps, 'hasEndedAt' | 'success'>,
    AnnotationStatusInterface {
  finalResultName: string;
  annotatedResultName?: string;
  originalResultName?: string;
  annotatedTimestamp?: string;
}

export const DataGridOperationResultCell = ({
  annotationStatus,
  annotatedResultName,
  originalResultName,
  finalResultName,
  annotatedTimestamp,
  hasEndedAt,
  success
}: DataGridOperationResultCellProps): JSX.Element => {
  const { formatWithDefaultTimezone } = useFormatTimezone();
  return (
    <div className="data-grid-operation-result-cell" data-testid="data-grid-operation-result-cell">
      <RSTooltip
        title={generateOperationTimestampTooltipTitle(
          annotationStatus,
          annotatedTimestamp && formatWithDefaultTimezone(annotatedTimestamp)
        )}
      >
        <div className="data-grid-operation-result-cell__operation-annotation-icon-container">
          <OperationAnnotationIcon annotationStatus={annotationStatus} />
        </div>
      </RSTooltip>
      <OperationResultChip
        title={
          <OperationResultTooltipTitle
            annotationStatus={annotationStatus}
            originalResult={originalResultName}
            annotatedResult={annotatedResultName}
          />
        }
        label={finalResultName}
        hasEndedAt={hasEndedAt}
        success={success}
      />
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { GetDeviceOperationsPerformanceQueryVariables } from '../../../../__generated__/graphql';
import { QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE } from '../../../../services/queries';
import { calculateACDCycleOperationsResultSuccessRates, getAcdCycleOperationsCounts } from '../../../../utilities';
import { DataGridPerformanceMetricCell } from '../../../5-elements';

interface DataGridPerformanceMetricsCellWithQueryProps {
  variables: GetDeviceOperationsPerformanceQueryVariables;
}

/*
  A wrapper of DataGridPerformanceMetricCell with independent performance query, plus the handling of the loading
  and error statuses of the query.
*/
export const DataGridPerformanceMetricsCellWithQuery = ({
  variables
}: DataGridPerformanceMetricsCellWithQueryProps): JSX.Element => {
  const { t } = useTranslation();
  const { loading, data, error } = useQuery(QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE, {
    variables,
    fetchPolicy: 'cache-first'
  });

  const acdCycleOperationsCount = getAcdCycleOperationsCounts(data);

  const successRates = calculateACDCycleOperationsResultSuccessRates(
    acdCycleOperationsCount.acdCycleOperationsSuccess,
    acdCycleOperationsCount.acdCycleOperationsFailed,
    acdCycleOperationsCount.acdCycleOperationsPrevSuccess,
    acdCycleOperationsCount.acdCycleOperationsPrevFailed
  );

  if (loading) {
    return (
      <div
        className="data-grid-performance-metrics-cell-with-query"
        data-testid="data-grid-performance-metrics-cell-with-query-loading"
      >
        <Skeleton className="data-grid-performance-metrics-cell-with-query__loading" variant="text" />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="data-grid-performance-metrics-cell-with-query"
        data-testid="data-grid-performance-metrics-cell-with-query-error"
      >
        <div className="data-grid-performance-metrics-cell-with-query__error">{t('noData')}</div>
      </div>
    );
  }

  return (
    <div className="data-grid-performance-metrics-cell-with-query">
      <DataGridPerformanceMetricCell
        metric={{
          value: successRates.acdCycleOperationsSuccessRate,
          displayUnit: t('units.percentage')
        }}
        metricChange={{
          value: successRates.acdCycleOperationsSuccessRateChange,
          displayUnit: t('units.percentage')
        }}
        metricCount={{
          value: successRates.acdCycleOperationsCount,
          displayUnit: t('dataGrid.operationalPerformance.units.metricCount')
        }}
      />
    </div>
  );
};

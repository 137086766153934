import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { customerProgramsTableColumns } from './customer-programs-table-columns';
import { generateCustomerProgramRows } from './generate-customer-program-rows';
import { CustomerPrograms } from '../../../../../types';
import { RSDataGrid } from '../../../../4-features';

interface CustomerProgramsTableProps {
  programs: CustomerPrograms;
}

export const CustomerProgramsTable = ({ programs }: CustomerProgramsTableProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="customer-programs-table" data-testid="customer-programs-table">
      <h3 className="customer-programs-table__title">{t('customerDetailsPage.sidePanel.programsTable.title')}</h3>
      <div className="customer-programs-table__data-grid">
        <RSDataGrid
          columns={customerProgramsTableColumns}
          rows={generateCustomerProgramRows(programs)}
          autoHeight={true}
        />
      </div>
    </div>
  );
};

import { FormControlLabel, FormControlLabelProps, Radio, RadioProps } from '@mui/material';
import classNames from 'classnames';
import { get } from 'lodash';
import { JSX } from 'react';

import { DataTestId } from '../../../types';

interface RSRadioOptionProps extends RadioProps, DataTestId {}

export interface RSRadioProps extends Omit<FormControlLabelProps, 'control'>, DataTestId {
  radioOptionProps?: RSRadioOptionProps;
}

/**
 * This component renders a RSRadio component. It is based on the MUI FormControlLabel and Radio components.
 * The API documentation for FormControlLabel: https://mui.com/material-ui/api/form-control-label/
 * The API documentation for Radio: https://mui.com/material-ui/api/radio/
 * NOTE: In most of the cases, this component is used in combination with <RSRadioGroup />. See the comments there
 *   for the usage.
 * Only commonly used properties are listed below.
 *
 * @param {ReactNode} label A text or an element to be used in an enclosing label element.
 * @param {RSRadioOptionProps} [radioOptionProps] The properties for the <Radio /> component.
 * @param {unknown} [value] The value of the component.
 * @returns {React.JSX.Element} A React element that renders a FormControlLabel component, which wraps a <Radio />
 *   control.
 */
export const RSRadio = ({ radioOptionProps, ...props }: RSRadioProps): JSX.Element => {
  return (
    <FormControlLabel
      {...props}
      className={classNames('rs-radio__form-control-label', props.className)}
      data-testid={get(props, 'data-testid') || 'rs-radio-form-control-label'}
      control={<Radio {...radioOptionProps} className={classNames('rs-radio', radioOptionProps?.className)} />}
    />
  );
};

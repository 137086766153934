import { GridColDef } from '@mui/x-data-grid';

import { DeviceRowDevice } from './generate-device-rows';
import { appConfig } from '../../../../configs';
import i18n from '../../../../i18n';
import { ACDCycleOperationResultSuccessRates } from '../../../../utilities';
import { DataGridHeaderCell, DataGridLink, DataGridPerformanceMetricCell } from '../../../5-elements';
import { DeviceStateSerialNumber } from '../device-state-serial-number';

export const deviceDataGridColumns: GridColDef<DeviceRowDevice>[] = [
  {
    field: 'serialNumber',
    hideable: false,
    sortable: false,
    flex: 0.75,
    minWidth: 150,
    headerName: i18n.t('devicesPage.tableColumns.serialNumber'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    },
    renderCell: ({ id, value }) => {
      return (
        <DeviceStateSerialNumber
          id={id}
          serialNumber={value.serialNumber}
          deviceLastState={value.deviceLastState}
          deviceOnlineStatus={value.deviceOnlineStatus}
        />
      );
    }
  },
  {
    field: 'operationalPerformance',
    hideable: false,
    sortable: false,
    flex: 0.75,
    minWidth: 216,
    headerName: i18n.t('devicesPage.tableColumns.operationalPerformance'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    },
    renderCell: ({ value }) => {
      const operationalPerformanceValue = value as ACDCycleOperationResultSuccessRates;
      return (
        <DataGridPerformanceMetricCell
          metric={{
            value: operationalPerformanceValue.acdCycleOperationsSuccessRate,
            displayUnit: i18n.t('units.percentage')
          }}
          metricChange={{
            value: operationalPerformanceValue.acdCycleOperationsSuccessRateChange,
            displayUnit: i18n.t('units.percentage')
          }}
          metricCount={{
            value: operationalPerformanceValue.acdCycleOperationsCount,
            displayUnit: i18n.t('dataGrid.operationalPerformance.units.metricCount')
          }}
        />
      );
    }
  },
  {
    field: 'operationalLifeCycle',
    hideable: false,
    sortable: false,
    flex: 0.75,
    minWidth: 185,
    headerName: i18n.t('devicesPage.tableColumns.operationalLifeCycle'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  },
  {
    field: 'rocOS',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 200,
    headerName: i18n.t('devicesPage.tableColumns.rocOS'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  },
  {
    field: 'connectorType',
    hideable: false,
    sortable: false,
    flex: 0.5,
    minWidth: 150,
    headerName: i18n.t('devicesPage.tableColumns.connectorType'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  },
  {
    field: 'customer',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 200,
    headerName: i18n.t('devicesPage.tableColumns.customer'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    },
    renderCell: ({ row: { customerId }, value }) => {
      return <DataGridLink to={`${appConfig.basePath}/customers/${customerId}`}>{value}</DataGridLink>;
    }
  },
  {
    field: 'site',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 200,
    headerName: i18n.t('devicesPage.tableColumns.site'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  },
  {
    field: 'program',
    hideable: false,
    sortable: false,
    flex: 0.4,
    minWidth: 110,
    headerName: i18n.t('devicesPage.tableColumns.program'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />;
    }
  }
];

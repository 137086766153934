import { DateTime } from 'luxon';

import i18n from '../../i18n';

export const calculateDuration = (startedAt?: string, endedAt?: string | null): string => {
  if (!startedAt || !endedAt) {
    return i18n.t('noData');
  }

  const parsedStartedAt = DateTime.fromISO(startedAt);
  const parsedEndedAt = DateTime.fromISO(endedAt);

  if (!parsedStartedAt.isValid || !parsedEndedAt.isValid) {
    return i18n.t('noData');
  }

  const duration = parsedEndedAt.diff(parsedStartedAt);

  // endedAt cannot be earlier than startedAt
  if (duration.toMillis() < 0) {
    return i18n.t('noData');
  }

  return duration.toFormat('hh:mm:ss');
};

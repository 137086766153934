import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { GetOperationDetailsQuery } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types';
import { BlobModalBase, BlobModalBaseProps, CodeViewer, ModalDrawerHeader } from '../../../4-features';

type MetadataType = Flatten<
  NonNullable<GetOperationDetailsQuery['deviceOperationByPK']>['deviceOperations']
>['metaData'];

interface MetadataModalProps extends Omit<BlobModalBaseProps, 'children'> {
  metadata: NonNullable<MetadataType>;
  metadataTitle: string;
  setOpenMetadata: Dispatch<SetStateAction<boolean>>;
}

export const MetadataModal = ({
  metadata,
  metadataTitle,
  setOpenMetadata,
  ...props
}: MetadataModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <BlobModalBase {...props} onClose={() => setOpenMetadata(false)}>
      <div className="metadata-modal" data-testid="metadata-modal">
        <ModalDrawerHeader
          title={t('operationsPage.operationDetails.metadataModal.title', { title: metadataTitle })}
          handleClose={() => setOpenMetadata(false)}
        />
        <div className="metadata-modal__metadata">
          <CodeViewer data={JSON.stringify(metadata, null, 2)} />
        </div>
        <div className="metadata-modal__footer" data-testid="metadata-modal-footer" />
      </div>
    </BlobModalBase>
  );
};

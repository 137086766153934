export enum ObjectUploadStatus {
  // Draft: The file is available in the ROC, but not uploaded to the cloud or being requested yet.
  Draft = 'DRAFT',
  // UploadRequestQueued: The file is not available in the cloud, an upload request has been sent to the queue.
  UploadRequestQueued = 'UPLOAD_REQUEST_QUEUED',
  // UploadRequestFailed: It could not ask the ROC to upload the file, possibly a network error (e.g. VPN)
  UploadRequestFailed = 'UPLOAD_REQUEST_FAILED',
  // UploadRequested: A request has been sent to the ROC and wait for the ROC to respond.
  UploadRequested = 'UPLOAD_REQUESTED',
  // UploadDone: The file is available in the cloud.
  UploadDone = 'UPLOAD_DONE',
  // UploadFailed: The ROC cannot upload the file, perhaps a S3 problem.
  UploadFailed = 'UPLOAD_FAILED',
  // UploadFailedNotFound: The file that the user requested cannot be found on S3.
  UploadFailedNotFound = 'UPLOAD_FAILED_NOT_FOUND'
}

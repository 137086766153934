import { gql } from '../../../__generated__/gql';

export const MUTATION_REMOTE_OPERATION_IMAGES = gql(`
  mutation requestRemoteOperationImages($operationId: String!, $images: [DeviceRemoteImageRequest!]!) {
    requestRemoteDeviceOperationImages(deviceOperationId: $operationId, images: $images) {
      id
      deviceOperationImage {
        id
        status
        size
        fileName
        contentType
        recordedAt
        type
        visualType
        metaData
      }
    }
  }
`);

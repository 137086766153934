import { OptionsObject, SnackbarMessage, VariantType } from 'notistack';

import i18n from '../../i18n';

interface RSSnackbarMessage extends OptionsObject {
  message: SnackbarMessage;
}

export const constructSnackbarMessage = (
  title?: string,
  name?: string,
  message?: string,
  variant: VariantType = 'info'
): RSSnackbarMessage => {
  const titlePart = title ? (name ? `${title}${i18n.t('snackbar.titleSuffix')}` : title) : '';
  const namePart = name || '';
  const messagePart = message ? `${i18n.t('snackbar.messageDivider')}${message}` : '';
  const fullMessage: SnackbarMessage = titlePart + namePart + messagePart;

  // Put autoHideDuration configuration here as the global setting not working well with hot module loading
  const messageOptions: OptionsObject = { variant, className: variant, autoHideDuration: 10000 };
  return { message: fullMessage, ...messageOptions };
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_CONNECTOR_HOLDER_TYPES = gql(`
  query getConnectorHolderTypes {
    connectorHolderTypes(orderBy: { name: ASC }) {
      id
      connectorType
      name
      code
    }
  }
`);

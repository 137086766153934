import classNames from 'classnames';
import { JSX, ReactNode } from 'react';

interface IconWithDescriptionProps {
  color?: 'primary' | 'warning' | 'error' | 'success';
  description?: ReactNode;
  testId?: string;
  icon: ReactNode;
}

/* NOTE: Due to the uncertainty of how the light looks like, this component may undergo significant change.
   If it goes beyond rendering-only (which means no business logic), it will be turn into a feature-component.
   Further check is required with Fresk.
*/
export const IconWithDescription = ({ color, description, testId, icon }: IconWithDescriptionProps): JSX.Element => {
  const iconClassName = classNames({
    'icon-with-description__icon': true,
    'icon-with-description__icon--primary': color === 'primary' || !color,
    'icon-with-description__icon--success': color === 'success',
    'icon-with-description__icon--warning': color === 'warning',
    'icon-with-description__icon--error': color === 'error'
  });

  return (
    <span className="icon-with-description" data-testid={testId}>
      <span className={iconClassName}>{icon}</span>
      <span className="icon-with-description__description">{description}</span>
    </span>
  );
};

import { JSX, ReactNode } from 'react';

import ExternalLinkIcon from '../../../assets/icons/external-link.svg?react';

/*
  For external link, we want a developer experience similar to <a>Click here</a>: to have the display contents wrapped.

  React only accepts using the property with the name `children` to allow wrapping contents in this way:
    <ExternalLink>
      <ChildComponent />
    </ExternalLink>

  Using other names, it is only possible to do:
    <ExternalLink value={<ChildComponent />} />

  By extending PropsWithChildren, the `children` property becomes optional. But here we want to make it required.
*/
interface ExternalLinkProps {
  icon?: JSX.Element;
  children: ReactNode;
  url: string;
}

/**
 * This component renders an ExternalLink component.
 * @param {JSX.Element} [icon] An optional icon for the external link. Default: using the <ExternalLinkIcon />.
 * @param {React.ReactNode} children The contents of the link.
 * @param {string} url The URL of the external link.
 * @returns {JSX.Element} An ExternalLink component.
 */
export const ExternalLink = ({ url, icon, children }: ExternalLinkProps): JSX.Element => {
  return (
    <a className="external-link" href={url} target="_blank" rel="noopener noreferrer">
      <span className="external-link__contents">
        <div className="external-link__icon">{icon || <ExternalLinkIcon />}</div>
        {children}
      </span>
    </a>
  );
};

export const generateSequenceNumberString = (sequence?: number | null): string => {
  if (!sequence) {
    return '000000';
  }
  return sequence.toString().padStart(6, '0');
};

export const generateOperationNumber = (serialNumber: string, sequence: number): string => {
  const sequenceString = generateSequenceNumberString(sequence);
  return `${serialNumber} - ${sequenceString}`;
};

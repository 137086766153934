import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE = gql(`
  query getDeviceOperationsPerformance(
    $filtersPrevSuccess: DeviceOperationBoolExp
    $filtersSuccess: DeviceOperationBoolExp
    $filtersPrevFailed: DeviceOperationBoolExp
    $filtersFailed: DeviceOperationBoolExp
  ) {
    totalACDCycleOperationsPrevSuccess: deviceOperationsAggregate(where: $filtersPrevSuccess) {
      aggregate {
        count
      }
    }
    totalACDCycleOperationsSuccess: deviceOperationsAggregate(where: $filtersSuccess) {
      aggregate {
        count
      }
    }
    totalACDCycleOperationsPrevFailed: deviceOperationsAggregate(where: $filtersPrevFailed) {
      aggregate {
        count
      }
    }
    totalACDCycleOperationsFailed: deviceOperationsAggregate(where: $filtersFailed) {
      aggregate {
        count
      }
    }
  }
`);

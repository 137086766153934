import { JSX } from 'react';

interface ScreenTitleProps {
  title: string | number | JSX.Element;
}

export const ScreenTitle = ({ title }: ScreenTitleProps): JSX.Element => {
  return (
    <div className="screen-title">
      <h1>{title}</h1>
    </div>
  );
};

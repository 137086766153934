import { gql } from '../../../__generated__/gql';

export const MUTATION_REMOTE_OPERATION_LOG = gql(`
  mutation requestRemoteOperationLog($deviceOperationId: String!) {
    requestRemoteDeviceOperationLog(deviceOperationId: $deviceOperationId) {
      id
      deviceOperationLog {
        id
        status
        size
        requestTries
        autoTriggered
      }
    }
  }
`);

import { gql } from '../../../__generated__/gql';

export const MUTATION_MODIFY_DEVICE_OPERATION = gql(`
  mutation modifyDeviceOperation($deviceOperationInput: DeviceOperationInput!) {
    modifyDeviceOperation(deviceOperationInput: $deviceOperationInput) {
      deviceOperation {
        id
        remark
        deviceOperationResult {
          id
          code
          name
        }
        operationalLifeCycle
        deviceOperationAnnotatedResultId
        deviceOperationAnnotatedResult {
          id
          code
          name
          isSuccessful
        }
        deviceOperationFinalResult {
          id
          isSuccessful
          name
          code
        }
        annotatedAt
        annotatedByUser {
          id
          firstName
          lastName
          email
        }
        deviceOperationAnnotationAudits(orderBy: { annotatedAt: DESC }) {
          id
          remark
          operationalLifeCycle
          annotatedAt
          annotatedByUser {
            id
            firstName
            lastName
          }
          deviceOperationResult {
            id
            name
          }
        }
      }
    }
  }
`);

export const execIfBothDefined = (
  val1: number | null | undefined,
  val2: number | null | undefined,
  fun: (val1: number, val2: number) => number
): number | null => {
  if (val1 !== undefined && val1 !== null && val2 !== undefined && val2 !== null) {
    return fun(val1, val2);
  }
  return null;
};

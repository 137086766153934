import qs from 'qs';
import { JSX, useContext } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { UserTimezoneContext } from '../../contexts';

export interface RSNavLinkTimezoneProps extends NavLinkProps {
  to: string; // Overridden the original type of `to` in LinkProps, so that it only accept a string
}

export const RSNavLinkTimezone = ({ to, children, ...props }: RSNavLinkTimezoneProps): JSX.Element => {
  const { userTimezone } = useContext(UserTimezoneContext);
  const inputUrl = new URL(to, window.location.href);
  const searchParametersWithTimezone = {
    ...qs.parse(inputUrl.search, { ignoreQueryPrefix: true }),
    timezone: userTimezone
  };
  const searchParameterString = qs.stringify(searchParametersWithTimezone, { arrayFormat: 'brackets' });
  const combinedRelativeLink = `${inputUrl.pathname}?${searchParameterString}`;

  return (
    <NavLink {...props} to={combinedRelativeLink}>
      {children}
    </NavLink>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_OPERATION_LOG = gql(`
  query getOperationLogByPK($operationLogId: uuid!) {
    deviceOperationLogByPK(id: $operationLogId) {
      id
      size
      status
      createdAt
      autoTriggered
      updatedAt
      requestTries
    }
  }
`);

import { CircularProgress } from '@mui/material';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

export const Loading = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="loading">
      <CircularProgress className="loading__circle" />
      {t('loading')}
    </div>
  );
};

import { JSX, MouseEventHandler } from 'react';

import { ModalDrawerHeader } from '../../modal-drawer-header';
import { BlobModalBase, BlobModalBaseProps } from '../blob-modal-base';

interface SiteImpressionModalProps extends Omit<BlobModalBaseProps, 'children'> {
  imageUrl?: string;
  handleClose: MouseEventHandler<HTMLButtonElement>;
}

/*
  Work in the future: implement loading and error handling in combination with the image fetch queries.
  The site impression image component in the current portal can be used as a reference.
  https://github.com/rocsys/cloud-core/blob/develop/cloud-portal/src/components/fleets/4-features/site-impression-image.tsx
*/
export const SiteImpressionModal = ({ imageUrl, handleClose, ...props }: SiteImpressionModalProps): JSX.Element => {
  return (
    <BlobModalBase {...props}>
      <div className="site-impression-modal" data-testid="site-impression-modal">
        <ModalDrawerHeader handleClose={handleClose} />
        <div className="site-impression-modal__image-container">
          <img className="site-impression-modal__image" data-testid="site-impression-image" src={imageUrl} />
        </div>
        {/* An empty footer for site impression */}
        <div className="site-impression-modal__footer"></div>
      </div>
    </BlobModalBase>
  );
};

import { QueryHookOptions, useQuery } from '@apollo/client';
import { useRef } from 'react';

import { PerformanceMetricsRender } from './performance-metrics-render';
import {
  DeviceOperationBoolExp,
  GetDeviceOperationsPerformanceQuery,
  GetDeviceOperationsPerformanceQueryVariables
} from '../../../__generated__/graphql';
import { QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE } from '../../../services/queries';
import {
  calculateACDCycleOperationsResultSuccessRates,
  generatePerformanceMetricsFilters,
  getAcdCycleOperationsCounts
} from '../../../utilities';

interface PerformanceMetricsProps {
  filters: DeviceOperationBoolExp;
  queryHookOptions?: QueryHookOptions<GetDeviceOperationsPerformanceQuery>;
}

export const PerformanceMetrics = ({ filters, queryHookOptions }: PerformanceMetricsProps) => {
  // The generated filter contains a field "startAt", which is very likely to be different in every state change.
  // This will cause re-rendering when an upper level component which uses this PerformanceMetrics changes its state.
  // To avoid the query being re-fetched because of (upper level component's) state update, we store the filter in a ref
  // when this component gets mounted, so that the "startAt" will not get updated in state update.
  const performanceMetricsFilter = useRef<GetDeviceOperationsPerformanceQueryVariables | undefined>(undefined);
  if (performanceMetricsFilter.current === undefined) {
    performanceMetricsFilter.current = generatePerformanceMetricsFilters(filters);
  }
  const { loading, data, error } = useQuery(QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE, {
    ...queryHookOptions,
    variables: { ...performanceMetricsFilter.current }
  });

  const counts = getAcdCycleOperationsCounts(data);

  const values = calculateACDCycleOperationsResultSuccessRates(
    counts.acdCycleOperationsSuccess,
    counts.acdCycleOperationsFailed,
    counts.acdCycleOperationsPrevSuccess,
    counts.acdCycleOperationsPrevFailed
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsSuccessRate={values.acdCycleOperationsSuccessRate}
      acdCycleOperationsSuccessRateChange={values.acdCycleOperationsSuccessRateChange}
      acdCycleOperationsCount={values.acdCycleOperationsCount}
      acdCycleOperationsCountChange={values.acdCycleOperationsCountChange}
      loadingTotalAcdCyclesOperationResult={loading}
      errorTotalAcdCyclesOperationResult={!!error}
    />
  );
};

import classNames from 'classnames';
import { isNil } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceStatusIcon, DeviceStatusIconProps } from '../../../4-features';

interface DeviceStatusProps extends DeviceStatusIconProps {
  statusValue?: string | number | null;
  stateName?: string | null;
  // sizeVariant: 'normal' takes a height of 24 px, 'small' 17 px.
  sizeVariant?: 'small' | 'normal';
}

export const DeviceStatus = (props: DeviceStatusProps): JSX.Element => {
  const { t } = useTranslation();
  const iconClassNames = classNames('device-status__icon-container', {
    'device-status__icon-container--small': props.sizeVariant === 'small'
  });
  const statusValueClassNames = classNames('device-status__value', {
    'device-status__value--small': props.sizeVariant === 'small'
  });

  return (
    <div className="device-status">
      <div className="device-status__value-container">
        <DeviceStatusIcon statusIconVariant={props.statusIconVariant} extraClassNames={iconClassNames} />
        <p className={statusValueClassNames} data-testid="device-status-value">
          {isNil(props.statusValue) ? t('deviceDetailsPage.deviceHealth.unknown') : props.statusValue}
        </p>
      </div>
      {props.stateName && (
        <p className="device-status__state-name" data-testid="device-status-state-name">
          {props.stateName}
        </p>
      )}
    </div>
  );
};

import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICE_IMAGE_URLS = gql(`
  query requestDeviceImageUrls($ids: [String!]!, $imageType: ImageType!) {
    requestDeviceImageUrls(ids: $ids, forDownload: false, imageType: $imageType) {
      url
      id
    }
  }
`);

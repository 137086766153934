import { isArray, isNil } from 'lodash';

export type PreprocessArgument = string[] | string | undefined;

export const preprocessArrayStringInput = (argument: PreprocessArgument): string[] | undefined => {
  if (isNil(argument) || isArray(argument)) {
    return argument;
  }
  return [argument];
};

import { Dispatch, SetStateAction, createContext } from 'react';

import { browserTimezone } from '../../utilities';

export interface UserTimezoneContextInterface {
  userTimezone: string;
  setUserTimezone: Dispatch<SetStateAction<string>>;
}

export const UserTimezoneContext = createContext<UserTimezoneContextInterface>({
  userTimezone: browserTimezone,
  setUserTimezone: () => {}
});

import { Tooltip, TooltipProps } from '@mui/material';
import { JSX } from 'react';

export type RSTooltipProps = TooltipProps;

export const RSTooltip = (props: RSTooltipProps): JSX.Element => {
  return (
    <Tooltip
      {...props}
      classes={{ popper: 'rs-tooltip__popper', tooltip: 'rs-tooltip' }}
      slotProps={{
        // Distance between the tooltip and the wrapped component: 5px
        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -9] } }] }
      }}
    >
      {props.children}
    </Tooltip>
  );
};

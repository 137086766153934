import { gql } from '../../../__generated__/gql';

export const QUERY_GET_DEVICES = gql(`
  query getDevices($offset: Int, $limit: Int, $filters: DeviceBoolExp, $orderBy: [DeviceOrderBy!]) {
    devicesAggregate(where: $filters) {
      aggregate {
        count
      }
    }
    devices(orderBy: $orderBy, offset: $offset, limit: $limit, where: $filters) {
      id
      serialNumber
      deviceCurrentPerformance {
        performanceDiff
        performanceLastPeriodPct
        performancePrevPeriodPct
        successLastPeriod
        successPrevPeriod
        totalDiffPct
        totalLastPeriod
        totalPrevPeriod
      }
      deviceLastState {
        id
        deviceStatus
        isActive
        stateName
        updatedAt
      }
      deviceMeasurementValues(
        where: { deviceMeasurementProperty: { name: { _in: ["is_online"] } }, endAt: { _isNull: true } }
      ) {
        id
        value
        updatedAt
        status
        deviceMeasurementProperty {
          id
          name
          unit
        }
      }
      operationalLifeCycle
      deviceSoftwareConfigurationActive {
        id
        rocosComposedVersion
      }
      connectorHolderType {
        id
        connectorType
      }
      site {
        id
        name
        customer {
          companyId
          company {
            id
            name
          }
        }
      }
      program {
        id
        name
      }
    }
  }
`);
